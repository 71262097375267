import EditableDataTable, {
  IEditableDataTableProps,
} from 'components/molecules/EditableDataTable/EditableDataTable';
import {
  COLUMN_WIDTH_ADJUSTMENTS,
  LOSS_METHODS_COLUMN_INDEX,
  OASIS_INFO_COLUMN_INDEX,
  PHYSICAL_PATH_VIEW_REVIEW_BASE_WIDTH_VALUE,
} from 'components/organisms/PhysicalPathReview/constants';
import {
  generateGenerationReviewColumns,
  generateLoadReviewColumns,
  generateTransmissionReviewColumns,
} from 'components/organisms/PhysicalPathReview/helpers';
import {
  IColumnWidthAdjustment,
  IViewTableProps,
} from 'components/organisms/PhysicalPathReview/types';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  VIEW_DATA_TABLE_SCROLL_ADJUST,
} from 'constants/styles';
import { EViewMode } from 'enums/View';
import useExpandableMiscInfos from 'hooks/useExpandableMiscInfos';
import LossMethodsEdit from 'hooks/useTransmissionEditColumns/LossMethodsEdit/LossMethodsEdit';
import OasisInfoEdit from 'hooks/useTransmissionEditColumns/OasisInfoEdit/OasisInfoEdit';
import {
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useCallback, useEffect, useMemo } from 'react';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
} from 'reduxes/Detail/types';
import styled from 'styled-components';
import {
  getColumnLossesEditRender,
  getColumnOasisInfoEditRender,
} from 'utils/views';
import { ECompositeState } from '../../../enums/ETag';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

// Specialize the DataTable component
const GenerationPhysicalSegmentDataTable = styled(
  (
    props: IEditableDataTableProps<IDetailGenerationPhysicalSegment> &
      IViewTableProps,
  ) => EditableDataTable<IDetailGenerationPhysicalSegment>(props),
)`
  ${(props) =>
    props.viewWidth === undefined ||
    props.viewWidth >= PHYSICAL_PATH_VIEW_REVIEW_BASE_WIDTH_VALUE
      ? ''
      : VIEW_DATA_TABLE_SCROLL_ADJUST}
`;

interface IPhysicalPathReviewProps {
  generationPhysicalSegments: IDetailGenerationPhysicalSegment[];
  isDisabled?: boolean;
  isExpandable?: boolean;
  isLossMethodsPopover?: boolean;
  isOasisInfoPopover?: boolean;
  isUnconstrained?: boolean;
  loadPhysicalSegments: IDetailLoadPhysicalSegment[];
  transmissionAllocations: IETagTransmissionAllocation[] | null;
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[];
  viewMode: EViewMode;
  viewWidth?: number;
  lossesLite?: boolean;
  lossesV2?: boolean;
  composite_state: ECompositeState | null;
}

const PhysicalPathReview = ({
  generationPhysicalSegments,
  isDisabled,
  isExpandable,
  isLossMethodsPopover = true,
  isOasisInfoPopover = true,
  isUnconstrained = false,
  loadPhysicalSegments,
  transmissionAllocations,
  transmissionPhysicalSegments,
  viewMode,
  viewWidth,
  lossesLite,
  lossesV2,
}: IPhysicalPathReviewProps): JSX.Element => {
  const getGenerationPhysicalSegmentsRowKey = useCallback(
    (record: IDetailGenerationPhysicalSegment): string =>
      record.physical_segment_id.toString(),
    [],
  );

  const {
    expandableConfig: generationExpandableConfig,
    updateRowMiscInfosExpandedKey: generationUpdateRowMiscInfosExpandedKey,
  } = useExpandableMiscInfos(getGenerationPhysicalSegmentsRowKey, true);

  const handleGenerationExpandMiscInfos = useCallback(
    (record: IDetailGenerationPhysicalSegment) => {
      generationUpdateRowMiscInfosExpandedKey(record);
    },
    [generationUpdateRowMiscInfosExpandedKey],
  );

  const getLoadPhysicalSegmentsRowKey = useCallback(
    (record: IDetailLoadPhysicalSegment): string =>
      record.physical_segment_id.toString(),
    [],
  );

  const {
    expandableConfig: loadExpandableConfig,
    updateRowMiscInfosExpandedKey: loadUpdateRowMiscInfosExpandedKey,
  } = useExpandableMiscInfos(getLoadPhysicalSegmentsRowKey, true);

  const handleLoadExpandMiscInfos = useCallback(
    (record: IDetailLoadPhysicalSegment) => {
      loadUpdateRowMiscInfosExpandedKey(record);
    },
    [loadUpdateRowMiscInfosExpandedKey],
  );

  const { generationColumns, loadColumns } = useMemo(() => {
    const generationColumns: IViewDataTableColumn<IDetailGenerationPhysicalSegment>[] =
      generateGenerationReviewColumns(
        isUnconstrained,
        isExpandable ? handleGenerationExpandMiscInfos : undefined,
      );
    const loadColumns: IViewDataTableColumn<IDetailLoadPhysicalSegment>[] =
      generateLoadReviewColumns(
        isUnconstrained,
        isExpandable ? handleLoadExpandMiscInfos : undefined,
      );

    if (!isUnconstrained) {
      // Since CSS does not offer a way to dynamically adjust width values using a
      // minmax function, we have to manually adjust widths ourselves.
      if (
        viewWidth === undefined ||
        viewWidth > PHYSICAL_PATH_VIEW_REVIEW_BASE_WIDTH_VALUE
      ) {
        COLUMN_WIDTH_ADJUSTMENTS.forEach(
          (columnWidthAdjustment: IColumnWidthAdjustment) => {
            generationColumns[columnWidthAdjustment.index].width =
              columnWidthAdjustment.percentageWidth;

            loadColumns[columnWidthAdjustment.index].width =
              columnWidthAdjustment.percentageWidth;
          },
        );
      } else {
        COLUMN_WIDTH_ADJUSTMENTS.forEach(
          (columnWidthAdjustment: IColumnWidthAdjustment) => {
            generationColumns[columnWidthAdjustment.index].width =
              columnWidthAdjustment.minWidth;

            loadColumns[columnWidthAdjustment.index].width =
              columnWidthAdjustment.minWidth;
          },
        );
      }
    }

    return {
      generationColumns,
      loadColumns,
    };
  }, [
    handleGenerationExpandMiscInfos,
    handleLoadExpandMiscInfos,
    isExpandable,
    isUnconstrained,
    viewWidth,
  ]);

  const getTransmissionPhysicalSegmentsRowKey = useCallback(
    (record: IETagTransmissionPhysicalSegment): string =>
      record.physical_segment_id.toString(),
    [],
  );

  const {
    expandableConfig: transmissionExpandableConfig,
    updateRowMiscInfosExpandedKey: transmissionUpdateRowMiscInfosExpandedKey,
  } = useExpandableMiscInfos(getTransmissionPhysicalSegmentsRowKey, true);

  const handleTransmissionExpandMiscInfos = useCallback(
    (record: IETagTransmissionPhysicalSegment) => {
      transmissionUpdateRowMiscInfosExpandedKey(record);
    },
    [transmissionUpdateRowMiscInfosExpandedKey],
  );

  const getInitialTransmissionOasisInfoData = useCallback(
    (record: IETagTransmissionPhysicalSegment): number | null =>
      record.physical_segment_id,
    [],
  );

  const getInitialLossesData = useCallback(
    (record: IETagTransmissionPhysicalSegment): any | null => {
      return {
        lossMethods: record.loss_methods,
        physicalSegmentId: record.physical_segment_id,
      };
    },
    [],
  );

  const oasisInfoEditRender =
    getColumnOasisInfoEditRender<IETagTransmissionPhysicalSegment>(isDisabled);

  const lossesEditRender =
    getColumnLossesEditRender<IETagTransmissionPhysicalSegment>(isDisabled);

  const transmissionColumns: IViewDataTableColumn<IETagTransmissionPhysicalSegment>[] =
    useMemo(() => {
      let transmissionColumns: IViewDataTableColumn<IETagTransmissionPhysicalSegment>[] =
        generateTransmissionReviewColumns(
          isLossMethodsPopover,
          isOasisInfoPopover,
          isUnconstrained,
          transmissionAllocations,
          viewMode,
          isExpandable ? handleTransmissionExpandMiscInfos : undefined,
          lossesLite,
          lossesV2,
        );

      if (
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF
      ) {
        transmissionColumns = transmissionColumns.map(
          (
            viewDataTableColumn: IViewDataTableColumn<IETagTransmissionPhysicalSegment>,
          ): IViewDataTableColumn<IETagTransmissionPhysicalSegment> => ({
            ...viewDataTableColumn,
          }),
        );

        transmissionColumns[OASIS_INFO_COLUMN_INDEX].title = (
          <OasisInfoEdit isDisabled={isDisabled} />
        );
        transmissionColumns[OASIS_INFO_COLUMN_INDEX].render =
          oasisInfoEditRender({
            getInitialData: getInitialTransmissionOasisInfoData,
          });

        if (lossesLite) {
          transmissionColumns[LOSS_METHODS_COLUMN_INDEX].render =
            lossesEditRender({
              getInitialData: getInitialLossesData,
            });

          transmissionColumns[LOSS_METHODS_COLUMN_INDEX].title = (
            <LossMethodsEdit isDisabled={isDisabled} />
          );
        }
      }

      // Since CSS does not offer a way to dynamically adjust width values using a
      // minmax function, we have to manually adjust widths ourselves.
      if (
        viewWidth === undefined ||
        viewWidth > PHYSICAL_PATH_VIEW_REVIEW_BASE_WIDTH_VALUE
      ) {
        COLUMN_WIDTH_ADJUSTMENTS.forEach(
          (columnWidthAdjustment: IColumnWidthAdjustment) => {
            transmissionColumns[columnWidthAdjustment.index].width =
              columnWidthAdjustment.percentageWidth;
          },
        );
      } else {
        COLUMN_WIDTH_ADJUSTMENTS.forEach(
          (columnWidthAdjustment: IColumnWidthAdjustment) => {
            transmissionColumns[columnWidthAdjustment.index].width =
              columnWidthAdjustment.minWidth;
          },
        );
      }

      return transmissionColumns;
    }, [
      isLossMethodsPopover,
      isOasisInfoPopover,
      isUnconstrained,
      transmissionAllocations,
      isExpandable,
      handleTransmissionExpandMiscInfos,
      lossesLite,
      lossesV2,
      viewMode,
      viewWidth,
      isDisabled,
      oasisInfoEditRender,
      getInitialTransmissionOasisInfoData,
      lossesEditRender,
      getInitialLossesData,
    ]);

  return (
    <Layout>
      <GenerationPhysicalSegmentDataTable
        columns={generationColumns}
        data={generationPhysicalSegments}
        expandable={isExpandable ? generationExpandableConfig : undefined}
        hideExpandIconColumn={true}
        maximumAllowableAdds={0}
        pagination={false}
        rowKey='physical_segment_id'
        tableLayout='fixed'
        viewWidth={viewWidth}
      />
      <EditableDataTable<IETagTransmissionPhysicalSegment>
        columns={transmissionColumns}
        data={transmissionPhysicalSegments}
        expandable={isExpandable ? transmissionExpandableConfig : undefined}
        hideExpandIconColumn={true}
        maximumAllowableAdds={0}
        pagination={false}
        rowKey='physical_segment_id'
        tableLayout='fixed'
      />
      <EditableDataTable<IDetailLoadPhysicalSegment>
        columns={loadColumns}
        data={loadPhysicalSegments}
        expandable={isExpandable ? loadExpandableConfig : undefined}
        hideExpandIconColumn={true}
        maximumAllowableAdds={0}
        pagination={false}
        rowKey='physical_segment_id'
        tableLayout='fixed'
      />
    </Layout>
  );
};

export default PhysicalPathReview;
