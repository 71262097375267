import { EMarketInfoMarket } from 'enums/ETag';
import { EPageMode } from 'enums/Page';
import useInitialData from 'hooks/useInitialData';
import usePrevious from 'hooks/usePrevious';
import { IAdjustedMarketInfos, IMarketInfos } from 'interfaces/Detail';
import { IETagMarketInfo } from 'interfaces/ETag';
import { useCallback } from 'react';
import { getInitialMarketData } from 'utils/detail';
import { getMarketDetailOptionForMarket } from 'utils/eTag';
import { ZonedDateTime } from 'utils/zonedDateTime';

const computeAdjustedMarketInfos = (
  marketInfoMarkets: EMarketInfoMarket[],
  marketInfos: IETagMarketInfo[],
  start_date: string | null,
): IAdjustedMarketInfos => {
  const adjustedMarketInfoContainer: IAdjustedMarketInfos = {
    adjustedMarketInfos: [],
    unchangedMarketInfos: [],
  };

  let allMarketsIncluded: boolean = true;

  for (let i: number = 0; i < marketInfoMarkets.length; i += 1) {
    const availableMarket: EMarketInfoMarket = marketInfoMarkets[i];
    const marketInfo: IETagMarketInfo | undefined = marketInfos.find(
      (eTagMarketInfo: IETagMarketInfo): boolean =>
        eTagMarketInfo.market_info_market === availableMarket,
    );
    if (marketInfo === undefined) {
      adjustedMarketInfoContainer.adjustedMarketInfos.push({
        data: getInitialMarketData(
          availableMarket,
          start_date
            ? ZonedDateTime.parseIso(
                start_date,
                getMarketDetailOptionForMarket(availableMarket).value.timeZone,
              )
            : ZonedDateTime.now(
                getMarketDetailOptionForMarket(availableMarket).value.timeZone,
              ).startOf('day'),
        ),
        market_info_market: availableMarket,
      });

      allMarketsIncluded = false;
    } else {
      adjustedMarketInfoContainer.adjustedMarketInfos.push(marketInfo);
      adjustedMarketInfoContainer.unchangedMarketInfos.push(marketInfo);
    }
  }

  if (allMarketsIncluded) {
    adjustedMarketInfoContainer.adjustedMarketInfos = marketInfos;
  }

  return adjustedMarketInfoContainer;
};

const useMarketInfos = (
  marketInfoMarkets: EMarketInfoMarket[],
  marketInfos: IETagMarketInfo[],
  isEditable: boolean,
  isDetailLoading: boolean,
  isDetailUpdating: boolean,
  pageMode: EPageMode,
  start_date: string | null,
): IMarketInfos => {
  const previousIsDetailLoading: boolean | undefined =
    usePrevious<boolean>(isDetailLoading);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious<boolean>(isDetailUpdating);
  const previousPageMode: EPageMode | undefined =
    usePrevious<EPageMode>(pageMode);

  const { adjustedMarketInfos, unchangedMarketInfos }: IAdjustedMarketInfos =
    computeAdjustedMarketInfos(marketInfoMarkets, marketInfos, start_date);

  const hasMarketInfosChanged = useCallback(
    (): boolean =>
      (!isDetailUpdating && previousIsDetailUpdating === true) ||
      (!isDetailLoading && previousIsDetailLoading === true) ||
      previousPageMode !== pageMode,
    [
      isDetailLoading,
      isDetailUpdating,
      pageMode,
      previousIsDetailLoading,
      previousIsDetailUpdating,
      previousPageMode,
    ],
  );

  const initialMarketInfos: IETagMarketInfo[] | undefined = useInitialData<
    IETagMarketInfo[]
  >(unchangedMarketInfos, hasMarketInfosChanged);

  return isEditable && !isDetailLoading
    ? {
        adjustedMarketInfos,
        initialMarketInfos,
      }
    : {
        adjustedMarketInfos: marketInfos,
        initialMarketInfos: marketInfos,
      };
};

export default useMarketInfos;
