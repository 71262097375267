// we restrict imports from moment and moment-timezone
// then suppress those restrictions only in files that either
// 1. wrap moment library with ZonedDateTime: forcing an explicit time zone
// 2. adapt a library component that requires moment
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';
import {
  TFormatTemplate,
  TGranularity,
  TTimeUnit,
  TTimeZone,
} from 'types/DateTime';
import { TDisabledDateHandler } from 'types/General';
import { captureError } from 'utils/error';
import { isEmptyValue } from 'utils/general';

/**
 * Not trying to re-invent the wheel here,
 * just wrapping moment, but using names that don't
 * suggest the moment library.
 * Key differences with moment:
 * 1. forced to pass a time zone in all factory methods
 * 2. immutable
 * The name "ZonedDateTime" is used in java.time,
 * it seems generic yet informative: this object
 * is a date and time together with a time zone.
 */
export class ZonedDateTime {
  private _wrappedMoment: Moment;

  /**
   * We want to insure each moment has an explicitly set
   * time zone, so we make the constructor private.
   * The only way to construct ZonedDateTime instances
   * is to call static factory methods, which
   * require time zone parameters.
   * @param wrappedMoment underlying moment instance
   * which we are hiding but using for all computations
   * @private external callers must use static factory methods
   */
  private constructor(wrappedMoment: Moment) {
    if (!wrappedMoment || !wrappedMoment.isValid()) {
      throw new Error(`Invalid wrapped moment: ${wrappedMoment}`);
    }
    if (wrappedMoment.tz() === undefined) {
      throw new Error(`No time zone in wrapped moment ${wrappedMoment}`);
    }
    this._wrappedMoment = wrappedMoment;
  }

  /**
   * Static factory method. Forces a time zone parameter so
   * that the default browser time zone is not accidentally used.
   * @param value the string to parse
   * @param timeZone the parsed result will have this time zone
   * @param template specify the expected shape of the date to parse
   */
  static parse(value: string, timeZone: TTimeZone, template: TFormatTemplate) {
    return new ZonedDateTime(moment.tz(value, template, timeZone));
  }

  /**
   * Static method that allows potentially null values to
   * be passed but will throw and error if they are null
   * @param dateString the string to parse
   * @param timeZone the parsed result will have this time zone
   */
  static strictParse = (
    dateString: string | null,
    timeZone: TTimeZone,
  ): ZonedDateTime => {
    if (dateString === null) {
      throw new Error('Missing time in string');
    } else {
      return ZonedDateTime.parseIso(dateString, timeZone);
    }
  };

  /**
   * Static factory method. Expects input to be an iso 8601 string
   * with offset, such as
   * 2021-01-18T09:08:32-06:00
   * 2021-01-18T15:08:32-00:00
   * 2021-01-18T15:08:32+00:00
   * 2021-01-18T15:08:32Z
   * @param isoStringWithOffset a string of the format shown above
   * @param timeZone a valid time zone id
   */
  static parseIso(
    isoStringWithOffset: string,
    timeZone: TTimeZone,
  ): ZonedDateTime {
    const parsed: ZonedDateTime | undefined = this.parseIsoOrUndefined(
      isoStringWithOffset,
      timeZone,
    );
    if (parsed === undefined) {
      captureError(
        new Error('Could not parse iso string ' + isoStringWithOffset),
      );

      return ZonedDateTime.fromMoment(moment(0), timeZone);
    }
    return parsed;
  }

  /**
   * Static factory method. Expects input to be an iso 8601 string
   * with offset, such as
   * 2021-01-18T09:08:32-06:00
   * 2021-01-18T15:08:32-00:00
   * 2021-01-18T15:08:32+00:00
   * 2021-01-18T15:08:32Z
   * Gives undefined if input cannot be parsed
   * @param isoStringWithOffset a string of the format shown above
   * @param timeZone a valid time zone id
   */
  static parseIsoOrUndefined(
    isoStringWithOffset: string | null | undefined,
    timeZone: TTimeZone,
  ): ZonedDateTime | undefined {
    if (isEmptyValue(isoStringWithOffset)) {
      return undefined;
    }
    const parsed = moment.tz(
      isoStringWithOffset!,
      ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DD'],
      timeZone,
    );
    if (!parsed.isValid()) {
      return undefined;
    } else {
      return new ZonedDateTime(parsed);
    }
  }

  /**
   * Factory method for new zoned date time in given zone.
   * @param timeZone valid time zone
   */
  static now(timeZone: TTimeZone): ZonedDateTime {
    return new ZonedDateTime(moment.tz(timeZone));
  }

  /**
   * Factory method for creating a new zoned date time from
   * a given moment. This should only be called by the few classes
   * that have to interact with a library that uses moment.
   * This allows transforming Moments to ZonedDateTimes.
   * Preserving local time is used when a moment is selected
   * as a combination of year/month/day/hour, etc. We interpret
   * such a selection in the timeZone given, not in the moment's
   * timeZone. This is the meaning of "PreserveLocal".
   *
   * @param datetime the non-null moment to transform
   * @param timeZone required valid time zone for ZonedDateTime
   */
  static fromMomentPreserveLocal(
    datetime: moment.Moment,
    timeZone: TTimeZone,
  ): ZonedDateTime {
    return new ZonedDateTime(datetime.clone().tz(timeZone, true));
  }

  /**
   * Factory method for creating a new zoned date time from
   * a given moment. This should only be called by the few classes
   * that have to interact with a library that uses moment.
   * This allows transforming Moments to ZonedDateTimes.
   *
   * @param datetime the non-null moment to transform
   * @param timeZone required valid time zone for ZonedDateTime
   */
  static fromMoment(
    datetime: moment.Moment,
    timeZone: TTimeZone,
  ): ZonedDateTime {
    return new ZonedDateTime(datetime.clone().tz(timeZone));
  }

  /**
   * Factory method for creating a new zoned date time from a given
   * Date.
   * @param date the non-null date to transform
   * @param timeZone required valid time zone for ZonedDateTime
   */
  static fromDate(date: Date, timeZone: TTimeZone) {
    return new ZonedDateTime(moment(date.getTime()).tz(timeZone));
  }

  /**
   * Get browser default time zone.
   */
  static defaultTimeZone(): TTimeZone {
    return moment.tz.guess() as TTimeZone;
  }

  /**
   * Compute the number of time units between this ZonedDateTime and the given one.
   * @param startDateTime the "subtrahend"
   * @param unitOfTime e.g. minutes, days, hours
   */
  diff(startDateTime: ZonedDateTime, unitOfTime: TTimeUnit): number {
    return this._wrappedMoment.diff(startDateTime._wrappedMoment, unitOfTime);
  }

  /**
   * Apply a given format template.
   * @param template e.g. YYYY-MM-DD
   */
  format(template: string): string {
    return this._wrappedMoment.format(template);
  }

  /**
   * Alias for isformat().
   */
  toIsoString(): string {
    return this.isoFormat();
  }

  /**
   * Number of milliseconds since January 1, 1970 GMT.
   */
  epochMillis(): number {
    return this._wrappedMoment.valueOf();
  }

  /**
   * Get the time zone value of this instance.
   */
  timeZone(): TTimeZone {
    // this class always insures time zone is set
    return this._wrappedMoment.tz()! as TTimeZone;
  }

  /**
   * Get a new ZonedDateTime whose time is moved backward
   * from this time until the start of the day, hour, minute, etc.
   * @param timeUnit e.g. day, hour, minute
   */
  startOf(timeUnit: TTimeUnit): ZonedDateTime {
    return new ZonedDateTime(this._wrappedMoment.clone().startOf(timeUnit));
  }

  /**
   * Get a new zoned date time by adding given amount of time units.
   * @param amount number of time units, may be negative or 0
   * @param timeUnit e.g. hour, day
   */
  add(amount: number, timeUnit: TTimeUnit): ZonedDateTime {
    if (amount === 0) {
      // specialization for performance to avoid copying moment if we don't have to
      return this;
    }
    return new ZonedDateTime(this._wrappedMoment.clone().add(amount, timeUnit));
  }

  /**
   * Get a new zoned date time by subtracting given amount of time units.
   * @param amount number of time units, may be negative or 0
   * @param timeUnit e.g. hour, day
   */
  subtract(amount: number, timeUnit: TTimeUnit): ZonedDateTime {
    if (amount === 0) {
      // specialization for performance to avoid copying moment if we don't have to
      return this;
    }
    return new ZonedDateTime(
      this._wrappedMoment.clone().subtract(amount, timeUnit),
    );
  }

  /**
   * Test if this ZonedDateTime, truncated to the given granularity,
   * is before (strict) the given zoned date time, also truncated.
   * "Truncated" here means the result of ZonedDateTime.startOf(granularity)
   * @param zonedDateTime instant to compare to
   * @param granularity optional unit of time to truncate by. e.g. day, hour
   */
  isBefore(zonedDateTime: ZonedDateTime, granularity?: TGranularity): boolean {
    return this._wrappedMoment.isBefore(
      zonedDateTime._wrappedMoment,
      granularity,
    );
  }

  /**
   * Test if this ZonedDateTime, truncated to the given granularity,
   * is after (strict) the given zoned date time, also truncated.
   * "Truncated" here means the result of ZonedDateTime.startOf(granularity)
   * @param zonedDateTime instant to compare to
   * @param granularity optional unit of time to truncate by. e.g. day, hour
   */
  isAfter(zonedDateTime: ZonedDateTime, granularity?: TGranularity): boolean {
    return this._wrappedMoment.isAfter(
      zonedDateTime._wrappedMoment,
      granularity,
    );
  }

  /**
   * Test if this ZonedDateTime, truncated to the given granularity,
   * is equal to the given zoned date time, also truncated.
   * "Truncated" here means the result of ZonedDateTime.startOf(granularity)
   * @param zonedDateTime instant to compare to
   * @param granularity optional unit of time to truncate by. e.g. day, hour
   */
  isSame(
    zonedDateTime: ZonedDateTime | undefined | null,
    granularity?: TGranularity,
  ): boolean {
    return (
      zonedDateTime !== undefined &&
      zonedDateTime !== null &&
      this._wrappedMoment.isSame(zonedDateTime._wrappedMoment, granularity)
    );
  }

  /**
   * Test if this ZonedDateTime, truncated to the given granularity,
   * is after (lax) the given zoned date time, also truncated.
   * "Truncated" here means the result of ZonedDateTime.startOf(granularity)
   * @param zonedDateTime instant to compare to
   * @param granularity optional unit of time to truncate by. e.g. day, hour
   */
  isSameOrAfter(
    zonedDateTime: ZonedDateTime,
    granularity?: TGranularity,
  ): boolean {
    return this._wrappedMoment.isSameOrAfter(
      zonedDateTime._wrappedMoment,
      granularity,
    );
  }

  /**
   * Test if this ZonedDateTime, truncated to the given granularity,
   * is before (lax) the given zoned date time, also truncated.
   * "Truncated" here means the result of ZonedDateTime.startOf(granularity)
   * @param zonedDateTime instant to compare to
   * @param granularity optional unit of time to truncate by. e.g. day, hour
   */
  isSameOrBefore(
    zonedDateTime: ZonedDateTime,
    granularity?: TGranularity,
  ): boolean {
    return this._wrappedMoment.isSameOrBefore(
      zonedDateTime._wrappedMoment,
      granularity,
    );
  }

  /**
   * Get a new instance with the given time zone.
   * @param timeZone valid zone
   * @param preserveLocalTime if true, the epoch millis time will change to preserve local display of time
   */
  withTimeZone(timeZone: TTimeZone, preserveLocalTime: boolean) {
    if (this.timeZone() === timeZone) {
      return this;
    }
    return new ZonedDateTime(
      this._wrappedMoment.clone().tz(timeZone, preserveLocalTime),
    );
  }

  /**
   * Get a new instance with the given hour value.
   * @param hour valid hour value
   */
  withHour(hour: number): ZonedDateTime {
    if (this.getHour() === hour) {
      return this;
    }
    return new ZonedDateTime(this._wrappedMoment.clone().hour(hour));
  }

  /**
   * Get a new instance with the given minute value.
   * @param minute valid minute value
   */
  withMinute(minute: number): ZonedDateTime {
    if (this.getMinute() === minute) {
      return this;
    }
    return new ZonedDateTime(this._wrappedMoment.clone().minute(minute));
  }

  /**
   * Get a new instance with the given seconds value.
   * @param seconds valid seconds value
   */
  withSeconds(seconds: number) {
    if (this.getSecond() === seconds) {
      return this;
    }
    return new ZonedDateTime(this._wrappedMoment.clone().seconds(seconds));
  }

  /**
   * Get a new instance with the given milliseconds value.
   * @param milliseconds valid milliseconds value
   */
  withMilliseconds(milliseconds: number) {
    if (this.getMillisecond() === milliseconds) {
      return this;
    }
    return new ZonedDateTime(
      this._wrappedMoment.clone().milliseconds(milliseconds),
    );
  }

  /**
   * Get the (timeZone-dependent) day of the month of this instant.
   */
  getDayOfMonth(): number {
    return this._wrappedMoment.date();
  }

  /**
   * Get the (timeZone-dependent) day of the week of this instant.
   * ex: 'tuesday'
   */
  getDayOfWeek(): string {
    return this._wrappedMoment.format('dddd');
  }

  /**
   * Get the (timeZone-dependent) hour of the day of this instant.
   */
  getHour(): number {
    return this._wrappedMoment.hour();
  }

  /**
   * Get the minute of the hour of this instant.
   */
  getMinute(): number {
    return this._wrappedMoment.minutes();
  }

  /**
   * Get the second of the minute of this instant.
   */
  getSecond(): number {
    return this._wrappedMoment.seconds();
  }

  /**
   * Get the millisecond of the second of this instant.
   */
  getMillisecond(): number {
    return this._wrappedMoment.milliseconds();
  }

  /**
   * Get the utc offset of this instant.
   */
  getUtcOffset(): number {
    return this._wrappedMoment.utcOffset();
  }

  /**
   * Should only be used by conversion utilities that must
   * interface with external libraries that depend on Moment.
   */
  asMoment(): Moment {
    return this._wrappedMoment.clone();
  }

  /**
   * Get a new Date from the epoch millis value of this instant.
   */
  asDate(): Date {
    return new Date(this._wrappedMoment.valueOf());
  }

  /**
   * e.g. 2020-01-03T00:08:00Z.
   */
  isoFormat(): string {
    return this._wrappedMoment.format('YYYY-MM-DDTHH:mm:ssZ');
  }

  /**
   * To put a string into a file name, we need to prevent any special characters
   * e.g. 20200103T0008.
   */
  fileFormat(): string {
    return this._wrappedMoment.format('YYYYMMDDTHHmm');
  }

  /**
   * Helper method to return the earliest of two zoned date times.
   */
  static min(zonedDateTime1: ZonedDateTime, zonedDateTime2: ZonedDateTime) {
    if (zonedDateTime1.isBefore(zonedDateTime2)) {
      return zonedDateTime1;
    } else {
      return zonedDateTime2;
    }
  }

  /**
   * Helper method to return the latest of two zoned date times.
   */
  static max(zonedDateTime1: ZonedDateTime, zonedDateTime2: ZonedDateTime) {
    if (zonedDateTime1.isAfter(zonedDateTime2)) {
      return zonedDateTime1;
    } else {
      return zonedDateTime2;
    }
  }

  /**
   * String version is same as "isoformat".
   */
  toString(): string {
    return this.isoFormat();
  }
}

export const toDisabledMoment = (
  timeZone: TTimeZone,
  disabledZonedDateTime?: TDisabledDateHandler,
): ((m: Moment | null) => boolean) | undefined =>
  disabledZonedDateTime === undefined
    ? undefined
    : (m: Moment | null): boolean =>
        disabledZonedDateTime(
          // we preserve local because the ant-design component will
          // create moments with the right year/month/day/hour/minute/second
          // and "PreserveLocal" means those values stay the same in the
          // converted ZonedDateTime object
          m === null
            ? null
            : ZonedDateTime.fromMomentPreserveLocal(m, timeZone),
        );

export const momentToZonedDateTime = (
  timeZone: TTimeZone,
  m: Moment | null,
): ZonedDateTime | null => {
  if (m == null) {
    return null;
  }
  // we preserve local because the ant-design component will
  // create moments with the right year/month/day/hour/minute/second
  // and "PreserveLocal" means those values stay the same in the
  // converted ZonedDateTime object
  const zonedDateTime: ZonedDateTime = ZonedDateTime.fromMomentPreserveLocal(
    m,
    timeZone,
  );
  return zonedDateTime;
};

export const zonedDateTimetoMoment = (
  zonedDateTime: ZonedDateTime | null,
): Moment | null => (zonedDateTime === null ? null : zonedDateTime.asMoment());
