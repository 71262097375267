import Spinner from 'components/atoms/Spinner/Spinner';
import ProfileDataGrid from 'components/organisms/ProfileDataGrid/ProfileDataGrid';
import { PROFILE_DATA_GRID_HEADER_ROW_HEIGHT_VALUE } from 'components/organisms/ProfileInformationView/constants';
import DataGridContextMenu from 'components/organisms/ProfileInformationView/DataGridContextMenu';
import { isInitialProfileDataGrid } from 'components/organisms/ProfileInformationView/helpers';
import {
  adHocProfilesToDetailState,
  editProfileDataGridToDetailState,
  getDisplayCountOptions,
  getEnergyProfileSnapshots,
  getRequestOptions,
  productProfilesToDetailState,
} from 'components/organisms/ProfileInformationView/ProfileInformationManager/helpers';
import ProfileInformationManagerActionBar from 'components/organisms/ProfileInformationView/ProfileInformationManager/ProfileInformationManagerActionBar';
import {
  EProfileView,
  IEditProfileDataGrid,
  IEditProfileDataGridEditedRowValues,
  IEditProfileDataGridEditedValues,
  IEditProfileDataGridRow,
  IProfileDateRange,
} from 'components/organisms/ProfileInformationView/types';
import DetectableOverflow from 'components/services/DetectableOverflow/DetectableOverflow';
import {
  CURRENT_PENDING_REQUEST_ID,
  CURRENT_PENDING_REQUEST_KEY,
  CURRENT_REQUEST_ID,
  CURRENT_REQUEST_KEY,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import {
  PHYSICAL_SEGMENTS_PROFILES_HEADER_ROW_HEIGHT_VALUE,
  PROFILE_DATA_GRID_ROW_HEIGHT_VALUE,
  STANDARD_SPACING,
} from 'constants/styles';
import { DATE_FORMAT } from 'constants/time';
import {
  EProfileDataGridCellType,
  EProfileFormat,
  EProfileTimeSplit,
  EProfileTimeUnit,
  ETableConfiguration,
} from 'enums/Detail';
import { EProfileSegment, ERequestType } from 'enums/ETag';
import { EUpdateState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import usePrevious from 'hooks/usePrevious';
import useProfileInformationReview from 'hooks/useProfileInformationReview/useProfileInformationReview';
import { IDataGridHandle, IOption } from 'interfaces/Component';
import {
  IAdHocProfile,
  IProfileDataGridCell,
  IProfileInterval,
} from 'interfaces/Detail';
import {
  IETagPhysicalSegmentProfile,
  IETagPhysicalSegmentsProfile,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DataGridHandle } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  detailEditETagDetail,
  detailRetrieveETagSnapshots,
  detailSetETagDetailSelectedRequestKey,
  detailSetSelectedProfileFormat,
} from 'reduxes/Detail/actions';
import { IDetailLoadPhysicalSegment } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TRow } from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import { TETagEditedPhysicalSegmentColumn } from 'types/ETag';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import {
  getIsReviewingProfileDataGrid,
  getRequestIdFromRequestKey,
} from 'utils/detail';
import { encodeIds, isEmptyValue } from 'utils/general';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';

const StyledProfileInformationManagerActionBar = styled(
  ProfileInformationManagerActionBar,
)`
  margin-bottom: ${STANDARD_SPACING};
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const retrieveProfileInformationManagerState = (state: TRootState) => {
  const {
    end_date,
    energyProfileSnapshots,
    generationPhysicalSegment,
    isDetailDeleted,
    selectedProfileFormat,
    selectedRequestKey,
    start_date,
    transactionStatuses,
    transmissionAllocations,
    updatingDetail,
    useUniqueProfiles,
    config,
    isPrintView,
  } = state.detail.present;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  const lossesLite = config?.losses_lite_enabled ?? false;
  const lossesV2 = config?.losses_v2_enabled ?? false;

  return {
    end_date,
    energyProfileSnapshots,
    generationPhysicalSegment,
    isDetailDeleted,
    isDetailUpdating,
    selectedProfileFormat,
    selectedRequestKey,
    start_date,
    timeZone,
    transactionStatuses,
    transmissionAllocations,
    useUniqueProfiles,
    losses: lossesLite,
    lossesV2,
    isPrintView,
  };
};

interface IProfileInformationManagerProps {
  encodedPermissionsId: string;
  generationPhysicalSegmentName: string;
  isDetailLoading: boolean;
  isLoadingProfiles: boolean;
  isLoadingSnapshots: boolean;
  isViewFull: boolean;
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null;
  loadPhysicalSegmentName: string;
  pageMode: EPageMode;
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null;
  selectedProfileChangeDate?: ZonedDateTime | null;
  setHasDataGridOverflowedX: (hasDataGridOverflowedX: boolean) => void;
  setHasDataGridOverflowedY: (hasDataGridOverflowedY: boolean) => void;
  setProfileDateOptions: (profileDateOptions: IOption<ZonedDateTime>[]) => void;
  setStartDateTime: (startDateTime: string | null) => void;
  setStopDateTime: (stopDateTime: string | null) => void;
  sortedTransmissionAllocations: IETagTransmissionAllocation[];
  toEntityId: TToEntityId;
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null;
  viewMode: EViewMode;
  editedProfileType: TETagEditedPhysicalSegmentColumn;
}

const ProfileInformationManager = ({
  encodedPermissionsId,
  generationPhysicalSegmentName,
  isDetailLoading,
  isLoadingProfiles,
  isLoadingSnapshots,
  isViewFull,
  loadPhysicalSegment,
  loadPhysicalSegmentName,
  pageMode,
  physicalSegmentsProfiles,
  selectedProfileChangeDate,
  setHasDataGridOverflowedX,
  setHasDataGridOverflowedY,
  setProfileDateOptions,
  setStartDateTime,
  setStopDateTime,
  sortedTransmissionAllocations,
  toEntityId,
  transmissionPhysicalSegments,
  viewMode,
}: IProfileInformationManagerProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    end_date,
    energyProfileSnapshots,
    generationPhysicalSegment,
    isDetailDeleted,
    isDetailUpdating,
    selectedProfileFormat,
    selectedRequestKey,
    start_date,
    timeZone,
    transactionStatuses,
    transmissionAllocations,
    useUniqueProfiles,
    losses,
    lossesV2,
    isPrintView,
  } = useSelector(retrieveProfileInformationManagerState);

  const isLoading: boolean =
    isDetailLoading || isLoadingProfiles || isLoadingSnapshots;
  const isReviewing: boolean = getIsReviewingProfileDataGrid(viewMode);
  const isEditable: boolean =
    (viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate) &&
    selectedProfileFormat === EProfileFormat.StartStop;

  const [dataGridElement, setDataGridElement] = useState<
    HTMLElement | undefined
  >();
  const [selectedProfileView, setSelectedProfileView] = useState<
    EProfileView | undefined
  >(EProfileView.Profiles);
  const [selectedProfileDate, setSelectedProfileDate] = useState<
    ZonedDateTime | undefined
  >(undefined);
  const [selectedProfileSegment, setSelectedProfileSegment] = useState<
    EProfileSegment | undefined
  >(EProfileSegment.Gen);
  const [selectedDisplayCount, setSelectedDisplayCount] = useState<
    number | undefined
  >(undefined);
  const [headerRowHeight, setHeaderRowHeight] = useState<number | undefined>(
    undefined,
  );
  const [maxRequestId, setMaxRequestId] = useState<number | undefined>(
    undefined,
  );
  const [minRequestId, setMinRequestId] = useState<number | undefined>(
    undefined,
  );
  const [showReliability, setShowReliability] = useState<boolean | undefined>(
    undefined,
  );
  const [selectedTableConfiguration, setSelectedTableConfiguration] = useState<
    ETableConfiguration | undefined
  >(ETableConfiguration.ProfileOnly);

  const [lossesV2Read, setLossesV2Read] = useState(false);

  const [lossesV2ReadAtETableConfig, setLossesV2ReadAtETableConfig] =
    useState(false);

  const [isSameTransAllocProfile, setIsSameTransAllocProfile] =
    useState<boolean>(true);
  useEffect(() => {
    if (!lossesV2Read && lossesV2) {
      setIsSameTransAllocProfile(!lossesV2);
      setLossesV2Read(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lossesV2]);

  const layoutRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const requestAnimationFrameIdRef = useRef<number | null>(null);
  const profileDataGridRef =
    useRef<IDataGridHandle>() as RefObject<IDataGridHandle>;
  const dataGridRef = useRef<DataGridHandle>() as RefObject<DataGridHandle>;
  const previousSelectedProfileDate: ZonedDateTime | undefined =
    usePrevious(selectedProfileDate);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);

  const [updatedGridRows, setUpdatedGridRows] = useState<
    IEditProfileDataGridRow[]
  >([]);

  const [firstChanges, setFirstChanges] = useState<IEditProfileDataGridRow[]>(
    [],
  );

  useEffect(() => {
    return () => {
      if (requestAnimationFrameIdRef.current !== null) {
        window.cancelAnimationFrame(requestAnimationFrameIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (pageMode === EPageMode.Edit) {
      setSelectedProfileView(EProfileView.Profiles);
    } else if (pageMode === EPageMode.Review) {
      if (profileDataGridRef.current) {
        profileDataGridRef.current.resetCopying();
      }
    }
  }, [pageMode]);

  const handleChange = useCallback(
    (editProfileDataGrid: IEditProfileDataGrid) => {
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editProfileDataGridToDetailState(
            editProfileDataGrid,
            timeZone,
          ),
        }),
      );
    },
    [dispatch, timeZone],
  );

  useEffect(() => {
    if (selectedProfileChangeDate) {
      setSelectedProfileDate(selectedProfileChangeDate);
    }
  }, [selectedProfileChangeDate]);

  useEffect(() => {
    setStartDateTime(start_date);
  }, [setStartDateTime, start_date]);

  useEffect(() => {
    setStopDateTime(end_date);
  }, [setStopDateTime, end_date]);

  const { energyProfileSnapshotsColumns, energyProfileSnapshotsDataSet } =
    useMemo(
      () => getEnergyProfileSnapshots(energyProfileSnapshots),
      [energyProfileSnapshots],
    );

  const { upperRequestId, requestIdOptions } = useMemo(
    () => getRequestOptions(transactionStatuses),
    [transactionStatuses],
  );

  const { defaultDisplayCount, displayCountOptions } = useMemo(
    () => getDisplayCountOptions(upperRequestId),
    [upperRequestId],
  );

  const [editedValues, setEditedValues] = useState<
    IEditProfileDataGridEditedValues | undefined
  >();

  const {
    adjustedPhysicalSegmentsProfiles,
    hasGenerationReliability,
    hasLoadReliability,
    initialProfileInformationDataSet,
    profileInformationColumns,
    profileInformationDataSet,
    profileInformationSummaryRows,
    integratedPhysicalSegmentsProfiles,
  } = useProfileInformationReview(
    end_date,
    generationPhysicalSegment,
    generationPhysicalSegmentName,
    isDetailDeleted,
    isDetailUpdating,
    isEditable,
    isLoading,
    loadPhysicalSegment,
    loadPhysicalSegmentName,
    pageMode,
    physicalSegmentsProfiles,
    previousIsDetailUpdating,
    selectedProfileFormat,
    selectedTableConfiguration,
    showReliability,
    sortedTransmissionAllocations,
    start_date,
    timeZone,
    transmissionAllocations,
    transmissionPhysicalSegments,
    useUniqueProfiles,
    viewMode,
    losses,
    lossesV2,
    isPrintView,
    editedValues,
    setUpdatedGridRows,
  );

  useEffect(() => {
    if (
      dataGridRef.current !== null &&
      dataGridRef.current.element !== null &&
      ((selectedProfileView === EProfileView.Profiles && !isLoadingProfiles) ||
        (selectedProfileView === EProfileView.Snapshots && !isLoadingSnapshots))
    ) {
      // Before we set the element for overflow detection we must wait until
      // the DOM has been painted so that we get a full update on the scroll
      // properties used in the overflow detection. We base the wait time on
      // the total number of rows of data since larger data sets will take
      // longer to render.
      const requestAnimationFrame = (count: number) => {
        if (count === 0) {
          setDataGridElement(dataGridRef.current!.element!);

          requestAnimationFrameIdRef.current = null;
        } else {
          requestAnimationFrameIdRef.current = window.requestAnimationFrame(
            () => {
              requestAnimationFrame(count - 1);
            },
          );
        }
      };

      requestAnimationFrame(
        Math.floor(
          (selectedProfileView === EProfileView.Profiles
            ? profileInformationDataSet.length
            : energyProfileSnapshotsDataSet.length) / 10,
        ),
      );
    }
  }, [
    dataGridRef,
    energyProfileSnapshotsDataSet.length,
    isLoadingProfiles,
    isLoadingSnapshots,
    isViewFull,
    profileInformationDataSet.length,
    selectedProfileView,
  ]);

  useEffect(() => {
    if (profileDataGridRef.current) {
      if (
        isLoading ||
        isDetailDeleted ||
        (isDetailUpdating && previousIsDetailUpdating === false)
      ) {
        profileDataGridRef.current.resetCopying();
      }
    }
  }, [
    isDetailDeleted,
    isDetailUpdating,
    isLoading,
    previousIsDetailUpdating,
    profileDataGridRef,
  ]);

  const profileDateOptions: IOption<ZonedDateTime>[] = useMemo(() => {
    const profileDateOptions: IOption<ZonedDateTime>[] = [];
    if (initialProfileInformationDataSet !== undefined) {
      let startDate: ZonedDateTime | null = null;
      let endDate: ZonedDateTime | null = null;

      for (const profileDataGridRow of initialProfileInformationDataSet) {
        const profileStartCell: IProfileDataGridCell | null | undefined =
          profileDataGridRow[PROFILE_PATH_START_KEY];

        if (profileStartCell === undefined) {
          throw new Error(
            `Missing key: ${PROFILE_PATH_START_KEY} for profileDataGridRow: ${JSON.stringify(
              profileDataGridRow,
            )}`,
          );
        }

        const profileStopCell: IProfileDataGridCell | null | undefined =
          profileDataGridRow[PROFILE_PATH_STOP_KEY];

        if (profileStopCell === undefined) {
          throw new Error(
            `Missing key: ${PROFILE_PATH_STOP_KEY} for profileDataGridRow: ${JSON.stringify(
              profileDataGridRow,
            )}`,
          );
        }

        if (profileStartCell !== null) {
          if (
            profileStartCell.type === EProfileDataGridCellType.DateTimeString
          ) {
            if (!isEmptyValue(profileStartCell.value)) {
              if (
                startDate === null ||
                startDate.isAfter(
                  ZonedDateTime.parseIso(
                    profileStartCell.value as string,
                    timeZone,
                  ),
                )
              ) {
                startDate = ZonedDateTime.parseIso(
                  profileStartCell.value as string,
                  timeZone,
                );
              }
            }
          } else {
            throw new Error(
              `Invalid data grid cell type for row with key: ${profileDataGridRow.id?.value} and cell with key: ${PROFILE_PATH_START_KEY}`,
            );
          }
        }

        if (profileStopCell !== null) {
          if (
            profileStopCell.type === EProfileDataGridCellType.DateTimeString
          ) {
            if (!isEmptyValue(profileStopCell.value)) {
              if (
                endDate === null ||
                endDate.isBefore(
                  ZonedDateTime.parseIso(
                    profileStopCell.value as string,
                    timeZone,
                  ),
                )
              ) {
                endDate = ZonedDateTime.parseIso(
                  profileStopCell.value as string,
                  timeZone,
                );
              }
            }
          } else {
            throw new Error(
              `Invalid data grid cell type for row with key: ${profileDataGridRow.id?.value} and cell with key: ${PROFILE_PATH_STOP_KEY}`,
            );
          }
        }
      }

      if (startDate !== null && endDate !== null) {
        let day: ZonedDateTime = startDate.startOf('day');

        while (day.isBefore(endDate)) {
          profileDateOptions.push({
            label: day.format(DATE_FORMAT),
            value: day,
          });

          day = day.add(1, 'day');
        }
      }
    }

    return profileDateOptions;
  }, [initialProfileInformationDataSet, timeZone]);

  useEffect(() => {
    setProfileDateOptions(profileDateOptions);
  }, [profileDateOptions, setProfileDateOptions]);

  const previousDefaultDisplayCount: number | undefined =
    usePrevious(defaultDisplayCount);

  useEffect(() => {
    if (selectedProfileView === EProfileView.Profiles) {
      setHeaderRowHeight(PHYSICAL_SEGMENTS_PROFILES_HEADER_ROW_HEIGHT_VALUE);
    } else if (selectedProfileView === EProfileView.Snapshots) {
      setHeaderRowHeight(PROFILE_DATA_GRID_HEADER_ROW_HEIGHT_VALUE);

      const displayCount: number =
        selectedDisplayCount === undefined ||
        previousDefaultDisplayCount !== defaultDisplayCount
          ? defaultDisplayCount
          : selectedDisplayCount;

      if (selectedRequestKey === undefined) {
        const maxRequestId: number = displayCount - 1;
        setMinRequestId(0);
        setMaxRequestId(maxRequestId < 0 ? 0 : maxRequestId);
      } else {
        const requestId: number =
          getRequestIdFromRequestKey(selectedRequestKey);

        if (
          requestId === CURRENT_REQUEST_ID ||
          requestId === CURRENT_PENDING_REQUEST_ID
        ) {
          const minRequestId: number = upperRequestId - displayCount + 1;
          setMinRequestId(minRequestId < 0 ? 0 : minRequestId);
          setMaxRequestId(upperRequestId);
        } else {
          const halfDisplayCount: number = displayCount >> 1;
          let minRequestId: number = requestId - halfDisplayCount;
          minRequestId = minRequestId < 0 ? 0 : minRequestId;

          let maxRequestId: number = minRequestId + displayCount - 1;
          maxRequestId =
            maxRequestId < minRequestId ? minRequestId : maxRequestId;

          if (maxRequestId > upperRequestId) {
            maxRequestId = upperRequestId;
            minRequestId = upperRequestId - displayCount + 1;
            minRequestId =
              minRequestId < 0
                ? 0
                : minRequestId > maxRequestId
                ? maxRequestId
                : minRequestId;
          }

          setMinRequestId(minRequestId);
          setMaxRequestId(maxRequestId);
        }
      }

      setSelectedDisplayCount(displayCount);
    }
  }, [
    defaultDisplayCount,
    previousDefaultDisplayCount,
    selectedDisplayCount,
    selectedProfileView,
    selectedRequestKey,
    upperRequestId,
  ]);

  useEffect(
    () => {
      if (maxRequestId !== undefined && minRequestId !== undefined) {
        retrieveEnergyProfileSnapshots(minRequestId, maxRequestId);
      }
    },
    // As an optimisation, we don't need to trigger an update for
    // retrieveEnergyProfileSnapshots these do not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxRequestId, minRequestId],
  );

  useEffect(() => {
    if (profileDateOptions.length > 0) {
      if (
        selectedRequestKey === CURRENT_REQUEST_KEY ||
        selectedRequestKey === CURRENT_PENDING_REQUEST_KEY
      ) {
        // Select date based on current time
        const currentTime: ZonedDateTime = ZonedDateTime.now(timeZone);
        const start: ZonedDateTime = profileDateOptions[0].value;
        const stop: ZonedDateTime =
          profileDateOptions[profileDateOptions.length - 1].value;

        if (currentTime.isBefore(start)) {
          setSelectedProfileDate(start);
        } else if (currentTime.isAfter(stop)) {
          setSelectedProfileDate(stop);
        } else {
          const currentDay: ZonedDateTime = currentTime.startOf('day');

          for (let i: number = 0; i < profileDateOptions.length; i += 1) {
            const current: ZonedDateTime = profileDateOptions[i].value;

            if (current.isSame(currentDay)) {
              setSelectedProfileDate(current);
              return;
            }
          }
        }
      } else {
        // Select date based on last_request_type
        let rangeStart: ZonedDateTime | null = null;

        if (adjustedPhysicalSegmentsProfiles !== null) {
          adjustedPhysicalSegmentsProfiles.find(
            (
              eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
            ): boolean => {
              let eTagPhysicalSegmentProfile: IETagPhysicalSegmentProfile | null =
                null;

              if (selectedProfileSegment === EProfileSegment.Gen) {
                eTagPhysicalSegmentProfile =
                  eTagPhysicalSegmentsProfile?.physical_segments_profiles ===
                  null
                    ? null
                    : eTagPhysicalSegmentsProfile?.physical_segments_profiles
                        .generation;
              }

              const found: boolean =
                eTagPhysicalSegmentProfile !== null &&
                eTagPhysicalSegmentProfile.profile !== null &&
                eTagPhysicalSegmentProfile.profile.last_request_type !==
                  ERequestType.None;

              if (found && eTagPhysicalSegmentsProfile.start !== null) {
                rangeStart = ZonedDateTime.parseIso(
                  eTagPhysicalSegmentsProfile.start,
                  timeZone,
                );
              }

              return found;
            },
          );
        }

        if (rangeStart !== null) {
          const currentDay: ZonedDateTime = ZonedDateTime.parseIso(
            rangeStart,
            timeZone,
          ).startOf('day');

          for (let i: number = 0; i < profileDateOptions.length; i += 1) {
            const current: ZonedDateTime = profileDateOptions[i].value;

            if (current.isSame(currentDay)) {
              setSelectedProfileDate(current);
              break;
            }
          }
        }
      }
    }
  }, [
    adjustedPhysicalSegmentsProfiles,
    profileDateOptions,
    selectedProfileSegment,
    selectedRequestKey,
    timeZone,
  ]);

  useEffect(() => {
    if (
      dataGridRef.current &&
      previousSelectedProfileDate !== selectedProfileDate
    ) {
      const rowIndex: number =
        integratedPhysicalSegmentsProfiles === null
          ? -1
          : integratedPhysicalSegmentsProfiles.findIndex(
              (
                eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
              ): boolean => {
                if (eTagPhysicalSegmentsProfile.start !== null) {
                  const start: ZonedDateTime = ZonedDateTime.parseIso(
                    eTagPhysicalSegmentsProfile.start,
                    timeZone,
                  ).startOf('day');

                  if (start.isSame(selectedProfileDate)) {
                    return true;
                  }
                }

                return false;
              },
            );

      if (rowIndex > 0) {
        // Internally React Data Grid uses the calculation:
        //
        // current.scrollTop = rowIdx * rowHeight
        //
        // to scroll the table contents. Unfortunately, this does not take into
        // account the header row height. So we effectively set the scrollTop
        // value directly by cancelling out the rowHeight mulitplication and
        // using our own calculation to include the correct row header height.
        dataGridRef.current.scrollToRow(rowIndex);
      } else if (rowIndex === 0) {
        dataGridRef.current.scrollToRow(0);
      }
    }
  }, [
    dataGridRef,
    integratedPhysicalSegmentsProfiles,
    previousSelectedProfileDate,
    selectedProfileDate,
    timeZone,
  ]);

  const { columns, EmptyRowRenderer, rows } = useMemo(() => {
    // The react-data-grid component can cause a memory leak if it is unmounted
    // during an internal update operation. We therefore prevent the component
    // from unmounting while we load data. Instead, we use the emptyRowsRenderer
    // to indicate when data is loading, with a Spinner, or if we know that the
    // data has loaded but has returned an empty array, then no indicator is
    // displayed.
    let EmptyRowRenderer: (() => JSX.Element) | undefined = (): JSX.Element => (
      <Spinner />
    );
    let columns: TProfileDataGridColumn[] = [];
    let rows: TProfileDataGridRow[] = [];

    if (selectedProfileView === EProfileView.Profiles && !isLoadingProfiles) {
      EmptyRowRenderer = undefined;
      columns = profileInformationColumns;
      rows = profileInformationDataSet;
    } else if (
      selectedProfileView === EProfileView.Snapshots &&
      !isLoadingSnapshots
    ) {
      EmptyRowRenderer = undefined;
      columns = energyProfileSnapshotsColumns;
      rows = energyProfileSnapshotsDataSet;
    }

    return { columns, EmptyRowRenderer, rows };
  }, [
    energyProfileSnapshotsColumns,
    energyProfileSnapshotsDataSet,
    isLoadingProfiles,
    isLoadingSnapshots,
    profileInformationColumns,
    profileInformationDataSet,
    selectedProfileView,
  ]);

  const handleOnRowsChange = useCallback(
    (updatedRows: TProfileDataGridRow[], extraRows?: TProfileDataGridRow[]) => {
      if (updatedRows.length !== rows.length) {
        throw new Error(
          `Unexpected change in row lengths, rows: ${rows.length} updatedRows: ${updatedRows.length}`,
        );
      }

      const editProfileDataGridRows: IEditProfileDataGridRow[] = [];

      rows.forEach((row: TProfileDataGridRow, index: number) => {
        const updatedRow: TProfileDataGridRow = updatedRows[index];

        if (row !== updatedRow) {
          editProfileDataGridRows.push({
            isSameTransAllocProfile,
            row,
            updatedRow,
          });
        }
      });

      if (editProfileDataGridRows.length > 0 || extraRows !== undefined) {
        handleChange({
          addExtraRows: extraRows,
          editProfileDataGridRows,
        });
      }

      let profileDataGridRows: IEditProfileDataGridRow[] = [];

      editProfileDataGridRows.forEach((editedRow) => {
        const existingRowIndex = updatedGridRows.findIndex(
          (row) =>
            row.updatedRow['path-start'] === editedRow.updatedRow['path-start'],
        );
        if (existingRowIndex === -1) {
          if (updatedGridRows.length === 0) {
            profileDataGridRows = editProfileDataGridRows;
          } else {
            profileDataGridRows = updatedGridRows.concat(
              editProfileDataGridRows,
            );
          }
        } else {
          updatedGridRows[existingRowIndex] = {
            ...updatedGridRows[existingRowIndex],
            updatedRow: editedRow.updatedRow,
          };
          profileDataGridRows = updatedGridRows;
        }
      });
      setUpdatedGridRows(profileDataGridRows);
    },
    [handleChange, isSameTransAllocProfile, rows, updatedGridRows],
  );

  const removeEditedRows = useCallback(
    (fromRow: number, toRow: number) => {
      const deletedRows = rows.slice(fromRow, toRow);
      let rowsToRemove: IEditProfileDataGridRow[] = [];
      deletedRows.forEach((row) => {
        const rowId = row['id'];
        if (rowId) {
          const idValue = rowId.value;
          const updatedRow = updatedGridRows.filter(
            (row) => row.updatedRow['id']?.value === idValue,
          );
          rowsToRemove = rowsToRemove.concat(updatedRow);
        }
      });
      const t = updatedGridRows.filter((row) => !rowsToRemove.includes(row));
      setUpdatedGridRows(t);
    },
    [rows, updatedGridRows],
  );

  const handleDeleteRowsAbove = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      const rowIndex = rows.findIndex(
        (row) => row['id']?.value === profileDataGridRow['id']?.value,
      );
      removeEditedRows(0, rowIndex);
      handleChange({ removeBeforeRow: profileDataGridRow });

      profileDataGridRef.current?.resetSelectionToFirstRow();
    },
    [handleChange, rows, removeEditedRows],
  );

  const handleDeleteRowsBelow = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      const rowIndex = rows.findIndex(
        (row) => row['id']?.value === profileDataGridRow['id']?.value,
      );
      removeEditedRows(rowIndex, rows.length - 1);
      handleChange({ removeAfterRow: profileDataGridRow });

      profileDataGridRef.current?.resetSelection();
    },
    [handleChange, rows, removeEditedRows],
  );

  const handleDeleteSelectedRows = useCallback(
    (fromRow: TRow, toRow: TRow) => {
      removeEditedRows(fromRow, toRow + 1);

      handleChange({ removeRows: { fromRow, toRow } });

      profileDataGridRef.current?.resetSelection();
    },
    [handleChange, removeEditedRows],
  );

  const handleInsertAboveRow = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      handleChange({ addBeforeRow: profileDataGridRow });
    },
    [handleChange],
  );

  const handleInsertBelowRow = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      handleChange({ addAfterRow: profileDataGridRow });
    },
    [handleChange],
  );

  const retrieveEnergyProfileSnapshots = useCallback(
    (minRequestId: number, maxRequestId: number) =>
      dispatch(detailRetrieveETagSnapshots(minRequestId, maxRequestId)),
    [dispatch],
  );

  const setSelectedProfileFormat = useCallback(
    (selectedProfileFormat: EProfileFormat | undefined) => {
      dispatch(detailSetSelectedProfileFormat({ selectedProfileFormat }));
    },
    [dispatch],
  );

  const setSelectedRequestKey = useCallback(
    (selectedRequestKey: string) =>
      dispatch(detailSetETagDetailSelectedRequestKey(selectedRequestKey)),
    [dispatch],
  );

  useEffect(() => {
    if (lossesV2 && !lossesV2ReadAtETableConfig) {
      setSelectedTableConfiguration(ETableConfiguration.POD);
      setLossesV2ReadAtETableConfig(true);
    } else {
      setSelectedTableConfiguration(ETableConfiguration.ProfileOnly);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, lossesV2]);

  useEffect(() => {
    setShowReliability(undefined);
  }, [isDetailLoading]);

  useEffect(() => {
    if (
      showReliability === undefined &&
      (hasGenerationReliability || hasLoadReliability)
    ) {
      setShowReliability(true);
    }
  }, [hasGenerationReliability, hasLoadReliability, showReliability]);

  const handleAdHocProfileSplitApply = useCallback(
    (
      adHocProfile: IAdHocProfile,
      editedValues?: IEditProfileDataGridEditedValues | undefined,
    ) => {
      setEditedValues(editedValues);
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: adHocProfilesToDetailState(
            adHocProfile,
            isSameTransAllocProfile,
          ),
        }),
      );
    },
    [dispatch, isSameTransAllocProfile],
  );

  const handleProductProfilesApply = useCallback(
    (combinedIntervals: IProfileInterval[]) => {
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: productProfilesToDetailState(
            combinedIntervals,
            isSameTransAllocProfile,
          ),
        }),
      );
    },
    [dispatch, isSameTransAllocProfile],
  );

  const isContextMenuDisabled: boolean = useMemo(
    () => isInitialProfileDataGrid(rows),
    [rows],
  );

  const summaryRows: TProfileDataGridSummaryRow[] | undefined = useMemo(
    () =>
      selectedProfileView === EProfileView.Profiles
        ? profileInformationSummaryRows
        : undefined,
    [profileInformationSummaryRows, selectedProfileView],
  );

  useEffect(() => {
    if (updatedGridRows.length > 0) {
      setFirstChanges(updatedGridRows);
    }
  }, [updatedGridRows, firstChanges.length]);

  const handleProfileDateRangeApply = useCallback(
    (profiles: IProfileDateRange) => {
      if (profiles) {
        const updatedProfileValuesByDateArray: IEditProfileDataGridEditedRowValues[] =
          [];
        let minutesDiff = '60';
        let currentMinutes = 0;

        updatedGridRows.forEach((row) => {
          const startDate = row.updatedRow[PROFILE_PATH_START_KEY];
          const stopDate = row.updatedRow[PROFILE_PATH_STOP_KEY];

          if (startDate && stopDate) {
            const sDate = ZonedDateTime.parseIso(
              startDate.value.toString(),
              timeZone,
            );
            const eDate = ZonedDateTime.parseIso(
              stopDate.value.toString(),
              timeZone,
            );
            if (currentMinutes === 0) {
              currentMinutes = sDate.getMinute();
            }
            const stopDateTime = ZonedDateTime.parseIso(
              stopDate.value.toString(),
              timeZone,
            );
            const startDateTime = ZonedDateTime.parseIso(
              startDate.value.toString(),
              timeZone,
            );
            minutesDiff = stopDateTime
              .diff(startDateTime, 'minutes')
              .toString();
            const startHour = ZonedDateTime.parseIso(
              startDate.value.toString(),
              timeZone,
            ).getHour();
            const endHour = ZonedDateTime.parseIso(
              stopDate.value.toString(),
              timeZone,
            ).getHour();
            updatedProfileValuesByDateArray.push({
              startHour,
              endHour,
              editedRow: row,
              startDate: sDate,
              endDate: eDate,
            });
          }
        });
        let currentTimeSplit: EProfileTimeSplit;
        switch (minutesDiff) {
          case '5':
            currentTimeSplit = EProfileTimeSplit.Five;
            break;
          case '15':
            currentTimeSplit = EProfileTimeSplit.Fifteen;
            break;
          case '60':
            currentTimeSplit = EProfileTimeSplit.Hourly;
            break;
          default:
            currentTimeSplit = EProfileTimeSplit.Hourly;
        }
        if (handleAdHocProfileSplitApply) {
          handleAdHocProfileSplitApply(
            {
              customIntervals: [],
              fixedDuration: 0,
              startDateTime: (profiles.startDateTime as ZonedDateTime)
                .startOf('day')
                .withMinute(currentMinutes),
              stopDateTime: (profiles.stopDateTime as ZonedDateTime)
                .startOf('day')
                .add(1, 'day'),
              timeSplit: currentTimeSplit,
              timeUnit: EProfileTimeUnit.Minute,
              genValue: null,
            },
            {
              startDateTime: (profiles.startDateTime as ZonedDateTime).startOf(
                'day',
              ),
              stopDateTime: (profiles.stopDateTime as ZonedDateTime)
                .startOf('day')
                .add(1, 'day'),
              carryOverValues: updatedProfileValuesByDateArray,
              originalRows: firstChanges,
            },
          );
        }
      }
    },
    [handleAdHocProfileSplitApply, timeZone, updatedGridRows, firstChanges],
  );

  return (
    <Layout ref={layoutRef}>
      <StyledProfileInformationManagerActionBar
        displayCountOptions={displayCountOptions}
        encodedPermissionsId={encodeIds([encodedPermissionsId, 'actionBar'])}
        isDisabled={isDetailDeleted || isDetailUpdating}
        isSameTransAllocProfile={isSameTransAllocProfile}
        isViewFull={isViewFull}
        onAdHocProfileSplitApply={handleAdHocProfileSplitApply}
        onProductProfileApply={handleProductProfilesApply}
        profileDateOptions={profileDateOptions}
        requestIdOptions={requestIdOptions}
        selectedDisplayCount={selectedDisplayCount}
        selectedProfileDate={selectedProfileDate}
        selectedProfileFormat={selectedProfileFormat}
        selectedProfileSegment={selectedProfileSegment}
        selectedProfileView={selectedProfileView}
        selectedRequestKey={selectedRequestKey}
        selectedTableConfiguration={selectedTableConfiguration}
        setIsSameTransAllocProfile={setIsSameTransAllocProfile}
        setSelectedDisplayCount={setSelectedDisplayCount}
        setSelectedProfileDate={setSelectedProfileDate}
        setSelectedProfileFormat={setSelectedProfileFormat}
        setSelectedProfileSegment={setSelectedProfileSegment}
        setSelectedProfileView={setSelectedProfileView}
        setSelectedRequestKey={setSelectedRequestKey}
        setSelectedTableConfiguration={setSelectedTableConfiguration}
        setShowReliability={setShowReliability}
        showReliability={showReliability}
        startDate={start_date}
        toEntityId={toEntityId}
        timeZone={timeZone}
        viewMode={viewMode}
        endDate={end_date}
        updatedGridRows={updatedGridRows}
        handleProfileDateRangeApply={handleProfileDateRangeApply}
        lossesV2={lossesV2}
      />
      <DetectableOverflow
        elementRef={dataGridElement}
        onOverflowedX={setHasDataGridOverflowedX}
        onOverflowedY={setHasDataGridOverflowedY}
      >
        <ProfileDataGrid
          columns={columns}
          dataGridRef={dataGridRef}
          emptyRowsRenderer={EmptyRowRenderer}
          headerRowHeight={headerRowHeight}
          initialRows={initialProfileInformationDataSet}
          isDisabled={
            isReviewing || isDetailDeleted || isDetailUpdating || !isEditable
          }
          isEditable={isEditable}
          onRowsChange={handleOnRowsChange}
          ref={profileDataGridRef}
          rowHeight={PROFILE_DATA_GRID_ROW_HEIGHT_VALUE}
          rows={rows}
          summaryRows={summaryRows}
          timeZone={timeZone}
          isVirtualised={false}
        />
      </DetectableOverflow>
      <DataGridContextMenu
        isDisabled={isContextMenuDisabled}
        onDeleteRowsAbove={handleDeleteRowsAbove}
        onDeleteRowsBelow={handleDeleteRowsBelow}
        onDeleteSelectedRows={handleDeleteSelectedRows}
        onInsertAboveRow={handleInsertAboveRow}
        onInsertBelowRow={handleInsertBelowRow}
      />
    </Layout>
  );
};

export default ProfileInformationManager;
