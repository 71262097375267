import { CURRENT_REQUEST_KEY, EDIT_ETAG_TAG_ID_LABEL } from 'constants/Detail';
import { EProfileFormat } from 'enums/Detail';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import {
  IDistributedTagItemDetailStateLoadTransform,
  IDistributedTagItemDetailStateTransform,
  IFailedDistributedTagItem,
} from 'interfaces/Detail';
import undoable from 'redux-undo';
import {
  getInitialRetrievingDistributedTagItems,
  updateValidations,
} from 'reduxes/Detail/helpers';
import {
  EDetailAction,
  IDetailState,
  TDetailAction,
} from 'reduxes/Detail/types';
import {
  TDetailRetrievingDistributedTagItems,
  TDetailValidations,
} from 'types/Detail';
import {
  applySameEnergyProfiles,
  getEditInfoKey,
  getInitialETagTagId,
} from 'utils/detail';

const initialState: IDetailState = {
  active: null,
  allTransmissionAllocations: null,
  allUnfilteredTransmissionAllocations: null,
  approval_right: null,
  approved_termination_time: null,
  cc_list: null,
  composite_state: null,
  computingLossesV2: EUpdateState.NotUpdating,
  config: undefined,
  contact_info: null,
  creator: null,
  description: null,
  draft_id: null,
  draftIsResolved: undefined,
  editedPhysicalSegmentsProfileType: null,
  end_date: null,
  energyProfileSnapshots: null,
  errorMessage: null,
  errorType: null,
  focusKey: null,
  generationPhysicalSegment: null,
  group_name: null,
  isDetailDeleted: false,
  isDetailEdited: false,
  isDetailValidating: false,
  isMarketInfosEdited: false,
  isMarketInfoNeeded: false,
  last_update_time: null,
  last_update_user: null,
  latestErroredDetailIdType: null,
  loadPhysicalSegment: null,
  lossAccountings: [],
  marketInfos: [],
  marketSegments: null,
  message_meta_data: null,
  name: null,
  notes: null,
  pageMode: EPageMode.None,
  physicalSegmentsProfiles: null,
  physical_segment_loss_percentages_v2: null,
  retrievingLossPercentages: ERetreiveState.NotRetrieving,
  productProfilesApplied: null,
  profileChanges: [],
  registryEntities: [],
  registryPoints: [],
  retrievingDetail: ERetreiveState.NotRetrieving,
  retrievingDistributedTagItems: getInitialRetrievingDistributedTagItems(),
  retrievingProfiles: ERetreiveState.NotRetrieving,
  retrievingSnapshots: ERetreiveState.NotRetrieving,
  security_key: null,
  selectedPlotTime: undefined,
  selectedProfileFormat: EProfileFormat.StartStop,
  selectedRequestKey: CURRENT_REQUEST_KEY,
  selectedRequestType: undefined,
  losses_lite_enabled: null,
  losses_v2_enabled: null,
  start_date: null,
  tag_id: getInitialETagTagId(getEditInfoKey(EDIT_ETAG_TAG_ID_LABEL, 0, 0)),
  tag_primary_key: undefined,
  template_id: undefined,
  test_flag: null,
  toEntity: null,
  transaction_type: null,
  transactionStatuses: [],
  transmission_physical_segments: null,
  transmissionAllocations: null,
  transmissionPriorityConfigurations: [],
  updatingDetail: EUpdateState.NotUpdating,
  useUniqueProfiles: false,
  validations: {},
  viewMode: EViewMode.None,
  isPrintView: false,
};

const detailReducer = (
  state: IDetailState = initialState,
  action: TDetailAction,
): IDetailState => {
  switch (action.type) {
    case EDetailAction.SetETagDetailInitialParameters: {
      return { ...state, ...action.payload };
    }
    case EDetailAction.SetETagDetailViewMode: {
      const { viewMode } = action.payload;
      return { ...state, viewMode };
    }
    case EDetailAction.RetrieveETagDetailStart: {
      return {
        ...state,
        retrievingDetail: ERetreiveState.RetrievingStarted,
      };
    }
    case EDetailAction.RetrieveETagDetailSuccess: {
      const { stateTransforms } = action.payload;
      return stateTransforms.reduce(
        (previousStateTransform, currentStateTransform) =>
          (state: IDetailState) =>
            currentStateTransform(previousStateTransform(state)),
        (state: IDetailState) => ({
          ...state,
          isDetailDeleted: false,
          retrievingDetail: ERetreiveState.RetrievingCompleted,
        }),
      )(state);
    }
    case EDetailAction.RetrieveETagDetailFailure: {
      const { detailIdType, errorMessage, isNotFound } = action.payload;
      return {
        ...state,
        errorMessage,
        isDetailDeleted: state.isDetailDeleted || isNotFound,
        isDetailEdited: state.isDetailEdited && !isNotFound,
        latestErroredDetailIdType: detailIdType,
        retrievingDetail: ERetreiveState.NotRetrieving,
      };
    }
    case EDetailAction.RetrieveETagProfilesStart: {
      const { selectedRequestKey } = action.payload;
      return {
        ...state,
        physicalSegmentsProfiles: [],
        retrievingProfiles: ERetreiveState.RetrievingStarted,
        selectedRequestKey: selectedRequestKey,
      };
    }
    case EDetailAction.RetrieveETagProfilesSuccess: {
      const { stateTransforms } = action.payload;
      return stateTransforms.reduce(
        (previousStateTransform, currentStateTransform) =>
          (state: IDetailState) =>
            currentStateTransform(previousStateTransform(state)),
        (state: IDetailState) => ({
          ...state,
          retrievingProfiles: ERetreiveState.RetrievingCompleted,
        }),
      )(state);
    }
    case EDetailAction.RetrieveETagProfilesFailure: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        errorMessage,
        retrievingProfiles: ERetreiveState.NotRetrieving,
      };
    }
    case EDetailAction.RetrieveETagSnapshotsStart: {
      return {
        ...state,
        retrievingSnapshots: ERetreiveState.RetrievingStarted,
      };
    }
    case EDetailAction.RetrieveETagSnapshotsSuccess: {
      const { stateTransforms } = action.payload;
      return stateTransforms.reduce(
        (previousStateTransform, currentStateTransform) =>
          (state: IDetailState) =>
            currentStateTransform(previousStateTransform(state)),
        (state: IDetailState) => ({
          ...state,
          retrievingSnapshots: ERetreiveState.RetrievingCompleted,
        }),
      )(state);
    }
    case EDetailAction.RetrieveETagSnapshotsFailure: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        errorMessage,
        retrievingSnapshots: ERetreiveState.NotRetrieving,
      };
    }
    case EDetailAction.SetETagDetailSelectedPlotTime: {
      const { selectedPlotTime } = action.payload;
      return {
        ...state,
        selectedPlotTime,
      };
    }
    case EDetailAction.EditETagDetail: {
      const { isDetailEdited, isMarketInfosEdited, stateTransform } =
        action.payload;

      return {
        ...(stateTransform === undefined ? state : stateTransform(state)),
        isDetailEdited:
          isDetailEdited === undefined ? state.isDetailEdited : isDetailEdited,
        isMarketInfosEdited:
          isMarketInfosEdited === undefined
            ? state.isMarketInfosEdited
            : isMarketInfosEdited,
      };
    }
    case EDetailAction.UpdateETagDetailsStart: {
      return {
        ...state,
        errorMessage: null,
        errorType: null,
        updatingDetail: EUpdateState.UpdateStarted,
      };
    }
    case EDetailAction.UpdateETagDetailsSuccess: {
      return {
        ...state,
        isDetailEdited: false,
        isMarketInfosEdited: false,
        updatingDetail: EUpdateState.UpdateCompleted,
      };
    }
    case EDetailAction.UpdateETagDetailsFailure: {
      const { errorMessage, errorType } = action.payload;
      return {
        ...state,
        errorMessage,
        errorType,
        updatingDetail: EUpdateState.NotUpdating,
      };
    }
    case EDetailAction.UpdateComputeLossesV2ETagDetailsStart: {
      return {
        ...state,
        errorMessage: null,
        errorType: null,
        computingLossesV2: EUpdateState.UpdateStarted,
      };
    }
    case EDetailAction.UpdateComputeLossesV2ETagDetailsSuccess: {
      return {
        ...state,
        isDetailEdited: false,
        isMarketInfosEdited: false,
        computingLossesV2: EUpdateState.UpdateCompleted,
      };
    }
    case EDetailAction.UpdateComputeLossesV2ETagDetailsFailure: {
      const { errorMessage, errorType } = action.payload;
      return {
        ...state,
        errorMessage,
        errorType,
        computingLossesV2: EUpdateState.NotUpdating,
      };
    }
    case EDetailAction.RetrieveETagDistributedTagItemsStart: {
      const { retrievingDistributedTagItems } = state;
      const { distributedTagItemDetailStateLoadTransforms } = action.payload;
      const updatedRetrievingDistributedTagItems: TDetailRetrievingDistributedTagItems =
        {
          ...retrievingDistributedTagItems,
        };

      distributedTagItemDetailStateLoadTransforms.forEach(
        (
          distributedTagItemDetailStateLoadTransform: IDistributedTagItemDetailStateLoadTransform,
        ) => {
          updatedRetrievingDistributedTagItems[
            distributedTagItemDetailStateLoadTransform.distributedTagItem
          ] = {
            errorMessage: null,
            retrieveState: ERetreiveState.RetrievingStarted,
          };
        },
      );

      return {
        ...state,
        retrievingDistributedTagItems: updatedRetrievingDistributedTagItems,
      };
    }
    case EDetailAction.RetrieveETagDistributedTagItemsSuccess: {
      const { distributedTagItemDetailStateTransforms } = action.payload;
      const updatedRetrievingDistributedTagItems: TDetailRetrievingDistributedTagItems =
        {
          ...state.retrievingDistributedTagItems,
        };
      let updatedState: IDetailState = { ...state, isDetailDeleted: false };

      distributedTagItemDetailStateTransforms.forEach(
        (
          distributedTagItemDetailStateTransform: IDistributedTagItemDetailStateTransform,
        ) => {
          const { detailStateTransform, distributedTagItem } =
            distributedTagItemDetailStateTransform;

          updatedState = detailStateTransform(updatedState);
          updatedRetrievingDistributedTagItems[distributedTagItem] = {
            errorMessage: null,
            retrieveState: ERetreiveState.RetrievingCompleted,
          };
          updatedState.retrievingDistributedTagItems =
            updatedRetrievingDistributedTagItems;
        },
      );

      return updatedState;
    }
    case EDetailAction.RetrieveETagDistributedTagItemsFailure: {
      const { retrievingDistributedTagItems } = state;
      const { failedDistributedTagItems } = action.payload;
      const updatedRetrievingDistributedTagItems: TDetailRetrievingDistributedTagItems =
        {
          ...retrievingDistributedTagItems,
        };
      let isNotFound: boolean = false;

      if (failedDistributedTagItems.length > 0) {
        failedDistributedTagItems.forEach(
          (failedDistributedTagItem: IFailedDistributedTagItem) => {
            updatedRetrievingDistributedTagItems[
              failedDistributedTagItem.distributedTagItem
            ] = {
              errorMessage: failedDistributedTagItem.errorMessage,
              retrieveState: ERetreiveState.NotRetrieving,
            };

            isNotFound = isNotFound || failedDistributedTagItem.isNotFound;
          },
        );
      } else {
        const distributedTagItemsToUpdate: EDistributedTagItem[] = Object.keys(
          retrievingDistributedTagItems,
        ) as EDistributedTagItem[];

        distributedTagItemsToUpdate.forEach(
          (distributedTagItem: EDistributedTagItem) => {
            updatedRetrievingDistributedTagItems[distributedTagItem] = {
              errorMessage: distributedTagItem,
              retrieveState: ERetreiveState.NotRetrieving,
            };
          },
        );
      }

      return {
        ...state,
        isDetailDeleted: state.isDetailDeleted || isNotFound,
        retrievingDistributedTagItems: updatedRetrievingDistributedTagItems,
      };
    }
    case EDetailAction.SetETagDetailValidation: {
      const { validations } = state;
      const { id, isValid } = action.payload;

      if (validations[id] === undefined || validations[id] !== isValid) {
        const updatedValidations: TDetailValidations = {
          ...validations,
        };

        updatedValidations[action.payload.id] = action.payload.isValid;

        return {
          ...state,
          validations: updatedValidations,
        };
      }

      return state;
    }
    case EDetailAction.SetETagDetailValidations: {
      const { validations } = state;
      const { detailValidations } = action.payload;
      const updatedValidations = updateValidations(
        validations,
        detailValidations,
      );

      return updatedValidations === validations
        ? state
        : {
            ...state,
            validations: updatedValidations,
          };
    }
    case EDetailAction.SetETagDetailIsDetailValidating: {
      const { isDetailValidating } = action.payload;
      return {
        ...state,
        isDetailValidating,
      };
    }
    case EDetailAction.SetETagDetailIsDetailDeleted: {
      const { isDetailDeleted } = action.payload;
      return {
        ...state,
        isDetailDeleted,
      };
    }
    case EDetailAction.SetETagDetailSelectedProfileFormat: {
      const { selectedProfileFormat } = action.payload;
      return {
        ...state,
        selectedProfileFormat,
      };
    }
    case EDetailAction.SetETagDetailSelectedRequestType: {
      const { selectedRequestType } = action.payload;
      return {
        ...state,
        profileChanges: [],
        selectedRequestType,
      };
    }
    case EDetailAction.SetETagDetailUseUniqueProfiles: {
      const { useUniqueProfiles } = action.payload;
      const updatedState: IDetailState = {
        ...state,
        isDetailEdited: true,
        useUniqueProfiles,
      };

      if (!useUniqueProfiles) {
        updatedState.physicalSegmentsProfiles = applySameEnergyProfiles(
          updatedState.physicalSegmentsProfiles,
        );
      }

      return updatedState;
    }
    case EDetailAction.SetETagDetailProductProfilesApplied: {
      const { productProfilesApplied } = action.payload;
      return {
        ...state,
        productProfilesApplied,
      };
    }
    case EDetailAction.SetEtagDetailIsMarketInfoNeeded: {
      const { isMarketInfoNeeded } = action.payload;
      return {
        ...state,
        isMarketInfoNeeded,
      };
    }

    case EDetailAction.SetETagDetailEditedPhysicalSegmentProfile: {
      const { editedPhysicalSegmentsProfileType } = action.payload;
      return {
        ...state,
        editedPhysicalSegmentsProfileType,
      };
    }

    case EDetailAction.RetrieveETagLossPercentagesV2Start: {
      return {
        ...state,
        retrievingLossPercentages: ERetreiveState.RetrievingStarted,
      };
    }
    case EDetailAction.RetrieveETagLossPercentagesV2Success: {
      const { stateTransforms } = action.payload;
      return stateTransforms.reduce(
        (previousStateTransform, currentStateTransform) =>
          (state: IDetailState) =>
            currentStateTransform(previousStateTransform(state)),
        (state: IDetailState) => {
          return {
            ...state,
            retrievingLossPercentages: ERetreiveState.RetrievingCompleted,
          };
        },
      )(state);
    }
    case EDetailAction.RetrieveETagLossPercentagesV2Failure: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        errorMessage,
        retrievingLossPercentages: ERetreiveState.NotRetrieving,
      };
    }
    case EDetailAction.SetETagDetailPhysicalSegmentLossPercentagesV2: {
      const { physical_segment_loss_percentages } = action.payload;
      return {
        ...state,
        physical_segment_loss_percentages_v2: physical_segment_loss_percentages,
      };
    }
    default: {
      return state;
    }
  }
};

export const undoableDetailReducer = undoable(detailReducer, {
  filter: (action: TDetailAction): boolean => action.payload.ignore !== true,
});
