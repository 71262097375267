import { IAggFunc, ValueFormatterFunc } from 'ag-grid-community';
import { IHeaderCellProps } from 'components/molecules/HeaderCell/HeaderCell';
import { ITemplateHeaderCellProps } from 'components/molecules/TemplateHeaderCell/TemplateHeaderCell';
import { EETagMessageSchema } from 'components/services/ETagWebSocket/types';
import {
  SUMMARY_PROFILE_OFF_PEAK_TOTAL_MW_KEY,
  SUMMARY_PROFILE_ON_PEAK_TOTAL_MW_KEY,
  SUMMARY_PROFILE_TOTAL_MW_KEY,
} from 'constants/ETag';
import { EAdHocFilter } from 'enums/AdHocFilter';
import { EApprovalStatus } from 'enums/Approval';
import {
  EColumnPosition,
  EColumnType,
  ECompositeState,
  EDraftScheduleStatus,
  EEditMode,
  EEnergyProfileType,
  ELossMethodEntryType,
  EMarketInfoMarket,
  EMarketInfoMarketType,
  EMarketInfoTransactionType,
  EMessageType,
  ERequestStatus,
  ERequestType,
  ETransactionType,
} from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import { IOption, IValidationMessage } from 'interfaces/Component';
import { IInterval } from 'interfaces/DateTime';
import { IEntityInfo } from 'interfaces/Entity';
import {
  IContactInfo,
  IEnergyProductInfo,
  IEnergyProfile,
  IPhysicalSegmentsProfileData,
  IMiscInfo,
  IPseContactInfo,
} from 'interfaces/General';
import { IPointInfo } from 'interfaces/Point';
import { IETagDetailPhysicalSegmentLossPercentages } from 'reduxes/Detail/types';
import { TFormatTemplate, TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import {
  TETagDraftId,
  TETagEnumeration,
  TETagRecordKey,
  TETagSummaryDealLinkageRecord,
  TETagTagPrimaryKey,
  TETagTemplateEnumeration,
  TETagTemplateId,
} from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export interface IETagIdentifier {
  draft_id: TETagDraftId;
  tag_primary_key: TETagTagPrimaryKey;
}

export interface IETagNotification {
  draft_id?: TETagDraftId;
  message_type?: EMessageType;
  submitted_tag_primary_key?: TETagTagPrimaryKey;
  submitted_ui_tag_id?: IETagTagId;
  tag_primary_key?: TETagTagPrimaryKey;
  to_entity?: TToEntityId;
}

export interface IETagCheckoutReportNotification {
  created_by: string;
  created_by_sub?: string;
  error_message?: string | null;
  generated: boolean;
  message_schema: EETagMessageSchema;
  to_entity?: TToEntityId;
  url?: string | null;
}

// Dummy comment for pipeline
export interface IETagExtendedIdentifier {
  composite_state: ECompositeState | null;
  draft_id: TETagDraftId;
  tag_primary_key: TETagTagPrimaryKey | undefined;
  ui_tag_id: string | null;
}

export interface IETagTagId {
  gca: IEntityInfo | null;
  key: string;
  lca: IEntityInfo | null;
  pse: IEntityInfo | null;
  tag_code: string | null;
  tag_primary_key: TETagTagPrimaryKey | null;
  ui_tag_id: string | null;
}

export interface IEditETagTagIds {
  addAfterETagTagId?: string;
  editETagTagIdMap?: Record<string, Partial<IETagTagId>>;
  removeETagTagId?: string;
}

export interface IETagSummaryAttribute extends IETagIdentifier {
  act_on_by_time: string | null;
  approval_right: boolean | null;
  approved_termination_time: string | null;
  cc_list: IEntityInfo[] | null;
  composite_state: ECompositeState | null;
  contact: string | null;
  creation_time: string | null;
  curtailed: boolean | null;
  denied_withdrawn: boolean | null;
  denied: boolean | null;
  withdrawn: boolean | null;
  end_date: string | null;
  fax: string | null;
  is_losses_tag: boolean | null;
  is_subhourly_tag: boolean | null;
  last_request_status: ERequestStatus | null;
  last_requestor: IEntityInfo | null;
  last_request_id: number | null;
  last_request_time: string | null;
  last_update_time: string | null;
  notes: string | null;
  pending_or_approved_termination_time: string | null;
  pending_requests: number | null;
  phone: string | null;
  sink: IPointInfo | null;
  source: IPointInfo | null;
  start_date: string | null;
  tag_id: IETagTagId;
  terminated_cancelled: boolean | null;
  test_flag: boolean | null;
  transaction_type: ETransactionType | null;
  misc_infos: string | null | any[];
  to_entity?: string | null;
  entity_code?: string;
}

export interface IETagSummaryAttributeState {
  summaryAttributeRemoved?: boolean;
  tag_code: string | null;
  ui_cpse: string | null;
  ui_gca: string | null;
  ui_lca: string | null;
  ui_sink: string | null;
  ui_source: string | null;
  ui_tag_id: string | null;
  to_entity?: string | null;
  entity_code?: string;
}

export interface IETagSummaryAttributeDataSet
  extends IETagSummaryAttribute,
    IETagSummaryAttributeState {}

export interface IETagSummaryDealLinkageRecord {
  eTagSummaryDealLinkageDataSets: IETagSummaryDealLinkage[];
  eTagSummaryDealLinkageError: TErrorMessage;
  eTagSummaryDealLinkageRetrieving: ERetreiveState;
  eTagSummaryDealLinkageLastRequestedAt: ZonedDateTime;
}

export interface IETagSummaryDealLinkageDataSet
  extends IETagSummaryDealLinkage {}

export interface IETagSummaryAttributeRecord {
  eTagSummaryAttributeDataSet?: IETagSummaryAttributeDataSet;
  eTagSummaryAttributeError: TErrorMessage;
  eTagSummaryAttributeRetrieving: ERetreiveState;
  eTagSummaryAttributeLastRequestedAt: ZonedDateTime;
}

export interface IETagCombinedSummaryAttributeRecord {
  eTagSummaryAttributeDataSet?: IETagSummaryAttributeDataSet[];
  eTagSummaryAttributeError: TErrorMessage;
  eTagSummaryAttributeRetrieving: ERetreiveState;
  eTagSummaryAttributeLastRequestedAt: ZonedDateTime;
}

export interface IETagSummaryDealLinkage {
  linkageDay?: string;
  ui_deal_count?: number | null;
  ui_deal_parent_name?: string | null;
  ui_deal_schedule_keys?: string | null;
  ui_deal_status?: string | null;
}

export interface IETagSummaryDealLinkageResponseRecord {
  day: string;
  schedule_keys: string[];
  schedule_count: number;
  parent_deals: any;
  status: string;
}

export interface IEnergyData {
  mw: number;
  pending: boolean;
  terminated: boolean;
  curtailed: boolean;
  reloaded: boolean;
}

export interface IHeData {
  start_time: string;
  energy?: IEnergyData | null;
}

export interface IETagSummaryProfile {
  day: string;
  he_01?: IHeData;
  he_02_extra?: IHeData;
  he_02?: IHeData;
  he_03?: IHeData;
  he_04?: IHeData;
  he_05?: IHeData;
  he_06?: IHeData;
  he_07?: IHeData;
  he_08?: IHeData;
  he_09?: IHeData;
  he_10?: IHeData;
  he_11?: IHeData;
  he_12?: IHeData;
  he_13?: IHeData;
  he_14?: IHeData;
  he_15?: IHeData;
  he_16?: IHeData;
  he_17?: IHeData;
  he_18?: IHeData;
  he_19?: IHeData;
  he_20?: IHeData;
  he_21?: IHeData;
  he_22?: IHeData;
  he_23?: IHeData;
  he_24?: IHeData;
  [SUMMARY_PROFILE_OFF_PEAK_TOTAL_MW_KEY]: number;
  [SUMMARY_PROFILE_ON_PEAK_TOTAL_MW_KEY]: number;
  [SUMMARY_PROFILE_TOTAL_MW_KEY]: number;
}

export interface IETagSummaryProfileState {
  summaryProfileRemoved?: boolean;
}

export interface IETagSummaryProfileDataSet
  extends IETagSummaryProfile,
    IETagSummaryProfileState {}

export interface IETagSummaryProfilesRecord {
  eTagSummaryProfiles: IETagSummaryProfileDataSet[];
  eTagSummaryProfilesError: TErrorMessage;
  eTagSummaryProfilesRetrieving: ERetreiveState;
  eTagSummaryProfilesLastRequestedAt?: ZonedDateTime;
}

export interface IETagsSummaryAttributeIdList {
  id_list: string[];
}

export interface IETagsSummaryAttributeIdListResponse {
  errorMessage: TErrorMessage;
  response: IETagsSummaryAttributeIdList;
}

export interface IETagsSummaryAttributeInformation {
  eTagIdentifiers: IETagIdentifier[];
}

export interface IETagsSummaryAttributeResponse {
  errorMessage: TErrorMessage;
  response: {
    data: IETagSummaryAttribute[];
  };
}

export interface IETagsSummaryAttributeBatchResponse {
  errorMessage: TErrorMessage;
  response: IETagSummaryAttribute[];
}

export interface IETagSummaryAttributeResponse {
  errorMessage: TErrorMessage;
  response: IETagSummaryAttribute;
}

export interface IETagsSummaryDealLinkageResponse {
  errorMessage: TErrorMessage;
  response: TETagSummaryDealLinkageRecord;
}

export interface IETagSummaryProfilesResponse {
  errorMessage: TErrorMessage;
  response: IETagSummaryProfile[];
}

export interface IETagSummaryProfilesBatch {
  erroredIds: string[];
  notFoundIds: string[];
  summaryProfiles: Record<string, IETagSummaryProfile[]>;
}

export interface IETagSummaryProfilesBatchResponse {
  errorMessage: TErrorMessage;
  response: IETagSummaryProfilesBatch;
}

export interface IETagAdHocFilters {
  enumOptions?: IOption<TETagEnumeration>[];
  filters: EAdHocFilter[];
}

export interface IETagTemplateAdHocFilters {
  enumOptions?: IOption<TETagTemplateEnumeration>[];
  filters: EAdHocFilter[];
}

export interface IETagColumnExpand {
  dataIndex: string;
  displayName: string;
}

export interface IETagColumnExpandAgGrid {
  field: string;
  headerName: string;
}

export interface IETagColumnData {
  dataIndex: string;
  dependantDataIndex?: string;
  description?: string;
  disabled?: boolean;
  displayName: string;
  expandTo?: IETagColumnExpand[];
  fixed?: EColumnPosition;
  isDealLinkageData?: true;
  isProfileData?: true;
  minColumnWidth: number;
  note?: string;
  onHeaderCell?: (columnData: IETagColumnData) => IHeaderCellProps;
  render?: (value: any, record: IETagDataSet, index?: number) => JSX.Element;
  shouldCellUpdate?: (
    prevRecord: IETagDataSet,
    nextRecord: IETagDataSet,
  ) => boolean;
  styleIndex?: string;
  type: EColumnType;
}

export type TChartDataType = 'category' | 'series' | 'time' | 'excluded';

export interface IETagColumnDataAgGrid {
  field: string;
  expandTo?: IETagColumnDataAgGrid[];
  expandToExtra?: IETagColumnDataAgGrid[];
  fixed?: EColumnPosition;
  headerName: string;
  isDealLinkageData?: true;
  isProfileData?: true;
  minWidth: number;
  maxWidth?: number;
  styleIndex?: string;
  type: EColumnType | undefined;
}

export interface IETagTemplateColumnData {
  dataIndex: string;
  fixed?: EColumnPosition;
  minColumnWidth: number;
  displayName: string;
  onHeaderCell?: (
    columnData: IETagTemplateColumnData,
  ) => ITemplateHeaderCellProps;
  render?: (value: any, record: IETagTemplate, index?: number) => JSX.Element;
  shouldCellUpdate?: (
    prevRecord: IETagTemplateDataSet,
    nextRecord: IETagTemplateDataSet,
  ) => boolean;
  styleIndex?: string;
  type: EColumnType;
}

export interface IETagTemplateColumnDataAgGrid {
  field: string;
  expandTo?: IETagColumnDataAgGrid[];
  fixed?: EColumnPosition;
  headerName: string;
  isProfileData?: true;
  minWidth: number;
  maxWidth?: number;
  styleIndex?: string;
  type: EColumnType | undefined;
}

export interface IETagProductData {
  generation_products: IEnergyProductInfo[];
  load_products: IEnergyProductInfo[];
  transmission_products: IEnergyProductInfo[];
}

export interface IETagData {
  recordKey: TETagRecordKey;
  summaryAttribute: IETagSummaryAttribute | undefined;
  summaryProfiles: IETagSummaryProfile[];
}

export interface IETagDraftCreateResponse {
  errorMessage: TErrorMessage;
  response: IETagIdentifier;
}

export interface IETagDraftDeleteResponse {
  errorMessage: TErrorMessage;
  response: IETagIdentifier;
}

export interface IETagValidateDraftResponse {
  errorMessage: TErrorMessage;
  response: {
    draft_id: IETagIdentifier['draft_id'];
    failure_list: string[];
    user: string;
    validated_on: string;
    warning_list: string[];
  };
}

export interface IETagValidateProfileChangeResponse {
  response: {
    failure_list: string[];
    profile_change_id: string;
    user: string;
    validated_on: string;
    warning_list: string[];
  };
}

export interface IETagRecomputeProfileChangeResponse {
  response: {
    id: string;
  };
  errorMessage: TErrorMessage;
}

export interface IETagValidationErrors {
  failure_list: IValidationMessage[];
  warning_list: IValidationMessage[];
}

export interface IETagDeleteResponse {
  errorMessage: TErrorMessage;
  response: IETagIdentifier;
}

export interface IETagTemplateCreateResponse {
  errorMessage: TErrorMessage;
  response: {
    templateId: string;
  };
}

export interface IETagTemplateCopyResponse {
  errorMessage: TErrorMessage;
  response: {
    templateId: string;
  };
}

export interface IETagDataSet
  extends IETagSummaryAttributeDataSet,
    IETagSummaryDealLinkageDataSet,
    IETagSummaryProfileDataSet {
  isFixedMap: Record<string, boolean>;
  isPrimary?: boolean;
  isProfilesLoading?: boolean;
  rowIndex?: number;
}

export interface IETagTemplateDataSet {
  active: boolean;
  cpse: string;
  creator: string;
  description: string;
  gca: string;
  id: string;
  isPrimary?: boolean;
  name: string;
  last_update_time: string;
  last_update_user: string;
  lca: string;
  rowIndex?: number;
  sink: string;
  source: string;
  tag_note: string;
  tag_type: string;
}

export interface IETagContactInfo {
  contact_info: IContactInfo | null;
}

export interface IETagMiscInfos {
  misc_infos: IMiscInfo[] | null;
}

export interface IETagMarketSegment extends IETagContactInfo, IETagMiscInfos {
  contracts: string[] | null;
  energy_product_ref: IEnergyProductInfo | null;
  market_segment_id: number;
  pse: IEntityInfo | null;
  pse_contact_info: IPseContactInfo | null;
}

export interface IETagMarketSegmentResponse {
  errorMessage: TErrorMessage;
  response: IETagMarketSegment[];
}

export interface IETagGenerationPhysicalSegment
  extends IETagContactInfo,
    IETagMiscInfos {
  contracts: string[] | null;
  energy_product_ref: IEnergyProductInfo | null;
  gca: IEntityInfo | null;
  generation_source: IPointInfo | null;
  market_segment_id: number | null;
  mo_code: IEntityInfo | null;
  physical_segment_id: number;
  profile_ref: number | null;
  pse: IEntityInfo | null;
}

export interface IETagLoadPhysicalSegment
  extends IETagContactInfo,
    IETagMiscInfos {
  contracts: string[] | null;
  energy_product_ref: IEnergyProductInfo | null;
  lca: IEntityInfo | null;
  load_sink: IPointInfo | null;
  market_segment_id: number | null;
  mo_code: IEntityInfo | null;
  physical_segment_id: number;
  profile_ref: number | null;
  pse: IEntityInfo | null;
}

export interface IETagTransmissionPhysicalSegment extends IETagMiscInfos {
  energy_product_ref: IEnergyProductInfo | null;
  loss_methods: string[] | null;
  market_segment_id: number | null;
  mo_code: IEntityInfo | null;
  oasis_info: string[] | null;
  physical_segment_id: number;
  pod: IPointInfo | null;
  pod_profile_ref: number | null;
  por: IPointInfo | null;
  por_profile_ref: number | null;
  pse: IEntityInfo | null;
  scheduling_entities: IEntityInfo[] | null;
  tp_code: IEntityInfo | null;
  physical_segment_loss_percentage?: string | number | null;
  physical_segment_loss_percentage_v2?: string | number | null;
}

export interface IETagPhysicalSegment {
  generation_physical_segment: IETagGenerationPhysicalSegment | null;
  load_physical_segment: IETagLoadPhysicalSegment | null;
  transmission_physical_segments: IETagTransmissionPhysicalSegment[] | null;
}

export interface IETagPhysicalSegmentResponse {
  errorMessage: TErrorMessage;
  response: IETagPhysicalSegment;
}

export interface IETagLossMethod {
  contract_numbers: string[] | null;
  loss_method_entry_type: ELossMethodEntryType | null;
  tag_ids: IETagTagId[] | null;
}

export interface IETagLossMethodEntry {
  loss_method: IETagLossMethod | null;
  request_ref: number | null;
  start: string | null;
  stop: string | null;
}

export interface IETagLossAccounting {
  loss_method_entries: IETagLossMethodEntry[] | null;
  physical_segment_ref: number | null;
}

export interface IETagLossAccountingResponse {
  errorMessage: TErrorMessage;
  response: IETagLossAccounting[];
}

export interface IETagTransmissionAllocation extends IETagMiscInfos {
  contract_number: string | null;
  nits_resource: string | null;
  physical_segment_ref: number | null;
  trans_alloc_customer_code: IEntityInfo | null;
  trans_alloc_id: number;
  trans_product_ref: IEnergyProductInfo | null;
}

export interface IEtagTransmissionPriorityConfiguration {
  key?: string;
  pid?: number | null;
  tp?: IEntityInfo | null;
  owner?: IEntityInfo | null;
  por?: IPointInfo | null;
  pod?: IPointInfo | null;
  source?: IPointInfo | null;
  sink?: IPointInfo | null;
  order?: number | null;
  product?: number | null;
}

export interface IETagTransmissionAllocationResponse {
  errorMessage: TErrorMessage;
  response: IETagTransmissionAllocation[];
}

export interface IETagTransactionStatus {
  approval_status: string | null;
  approval_status_type: string | null;
  approval_timestamp: string | null;
  approver_notes: string | null;
  call_timestamp: string | null;
  delivery_status: string | null;
  entity: IEntityInfo | null;
  from_entity: IEntityInfo | null;
}

export interface IETagTransactionStatuses {
  act_on_by_time: string | null;
  approval_rights: boolean | null;
  contact_info: IContactInfo | null;
  correction_id: number | null;
  notes: string | null;
  request_id: number;
  requestor: IEntityInfo | null;
  resolution_status: ERequestStatus | null;
  request_timestamp: string | null;
  statuses: IETagTransactionStatus[] | null;
  time_classification: string | null;
  transaction_message_types: string[] | null;
  ui_transaction_message_type: ERequestType | null;
}

export interface IETagTransactionStatusesResponse {
  errorMessage: TErrorMessage;
  response: IETagTransactionStatuses[];
}

export interface IETagPhysicalSegmentProfile {
  physical_segment_id: number;
  profile: IEnergyProfile | null;
  source_sink: IPointInfo | null;
}

export interface IETagTransmissionAllocationProfile {
  contract_number: string | null;
  last_request_type: ERequestType | null;
  profile_mw: number | null;
  trans_alloc_id: number | null;
}

export interface IETagTransmissionSegment {
  physical_segment_id: number | null;
  pod: IPointInfo | null;
  pod_energy_profile: IEnergyProfile | null;
  por: IPointInfo | null;
  por_energy_profile: IEnergyProfile | null;
  tp_code: IEntityInfo | null;
  transmission_limit: number | null;
  trans_alloc_profiles: IETagTransmissionAllocationProfile[] | null;
}

export interface IETagTransmissionPhysicalSegmentProfile {
  transmission_limit: number | null;
  transmission_segments: IETagTransmissionSegment[] | null;
}

export interface IETagPhysicalSegmentsProfiles {
  generation: IETagPhysicalSegmentProfile | null;
  load: IETagPhysicalSegmentProfile | null;
  transmission: IETagTransmissionPhysicalSegmentProfile | null;
}

export interface IETagPhysicalSegmentsProfile {
  key: string;
  physical_segments_profiles: IETagPhysicalSegmentsProfiles | null;
  start: string | null;
  stop: string | null;
}

export interface IETagPhysicalSegmentsProfilesResponse {
  errorMessage: TErrorMessage;
  response: IETagPhysicalSegmentsProfile[];
}

export interface IETagEnergyProfileSnapshot {
  last_request_type: ERequestType;
  mw: number | null;
  profile_id: number;
  request_id: number;
}

export interface IETagEnergyProfileSnapshotInterval {
  snapshots: IETagEnergyProfileSnapshot[];
  start: string | null;
  stop: string | null;
}

export interface IETagEnergyProfileSnapshots {
  max_request_id: number;
  min_request_id: number;
  snapshot_intervals: IETagEnergyProfileSnapshotInterval[];
}

export interface IETagEnergyProfileSnapshotsResponse {
  errorMessage: TErrorMessage;
  response: IETagEnergyProfileSnapshots;
}

export interface IETagRecomputeEnergyProfiles {
  profile: IPhysicalSegmentsProfileData[];
}

export interface IETagDefaultRange {
  start: string | null;
  stop: string | null;
}

export interface IETagTagMetaAttribute {
  cc_list: IEntityInfo[] | null;
  composite_state: ECompositeState | null;
  contact_info: IContactInfo | null;
  notes: string | null;
  test_flag: boolean | null;
  transaction_type: ETransactionType | null;
}

export interface IETagPath {
  loss_accountings: IETagLossAccounting[] | null;
  market_segments: IETagMarketSegment[] | null;
  physical_segments: IETagPhysicalSegment | null;
}

export interface IETagEnergyProfileDetail {
  energy_profile_type: EEnergyProfileType | null;
  limit_clearing: unknown;
  mw: number | null;
  start: string | null;
  start_ramp_dur: number | null;
  stop: string | null;
  stop_ramp_dur: number | null;
}

export interface IETagPathEnergyProfile {
  energy_profile_details: IETagEnergyProfileDetail[] | null;
  profile_id: number;
}

export interface IETagTransmissionAllocationProfileDetail {
  mw: number | null;
  start: string | null;
  stop: string | null;
}

export interface IETagZonedDateTimeTransmissionAllocationProfileDetail {
  mw: number | null;
  interval: IInterval;
}

export interface IETagZonedDateTimeEnergyProfileDetail {
  energy_profile_type: EEnergyProfileType | null;
  limit_clearing: unknown;
  mw: number | null;
  start_ramp_dur: number | null;
  stop_ramp_dur: number | null;
  interval: IInterval;
}

export interface IETagPathTransmissionAllocationProfile {
  trans_alloc_profile_attributes: IETagTransmissionAllocation | null;
  trans_alloc_profile_details:
    | IETagTransmissionAllocationProfileDetail[]
    | null;
}

export interface IETagPathProfile {
  energy_profiles: IETagPathEnergyProfile[] | null;
  trans_alloc_profiles: IETagPathTransmissionAllocationProfile[] | null;
}

export interface IETagDraft {
  default_range: IETagDefaultRange | null;
  draft_id: TETagDraftId;
  path: IETagPath | null;
  path_profile: IETagPathProfile | null;
  resolved?: boolean;
  security_key: string | null;
  tag_id: IETagTagId | null;
  tag_meta_attribute: IETagTagMetaAttribute | null;
}

export interface IETagDraftResponse {
  errorMessage: TErrorMessage;
  response: IETagDraft;
}

export interface IETagDraftUpdate {
  draft_id: TETagDraftId;
}

export interface IETagDraftUpdateResponse {
  errorMessage: TErrorMessage;
  response: IETagDraftUpdate;
}

export interface IETagDraftRecomputeEnergyProfilesResponse {
  errorMessage: TErrorMessage;
  response: IETagDraftUpdate;
}

export interface IETagDraftRecomputeTransAllocsResponse {
  errorMessage: TErrorMessage;
  response: IETagDraftUpdate;
}

export interface IETagTemplateMessageInfo {
  call_timestamp: string | null;
  from_entity: IEntityInfo | null;
  security_key: string | null;
  to_entity: IEntityInfo | null;
}

export interface IETagTemplateMessageMetaData {
  act_on_by_time: string | null;
  approval_rights: unknown;
  message_info: IETagTemplateMessageInfo | null;
  request_timestamp: string | null;
  time_classification: string | null;
}

export interface IETagTemplateTag {
  message_meta_data: IETagTemplateMessageMetaData | null;
  path: IETagPath | null;
  path_profile: IETagPathProfile | null;
  security_key: string | null;
  tag_id: IETagTagId | null;
  tag_meta_attribute: IETagTagMetaAttribute | null;
}

export interface IETagTemplate {
  active: boolean | null;
  creator: string | null;
  description: string | null;
  group_name: string | null;
  id: string;
  last_update_time: string | null;
  last_update_user: string | null;
  name: string | null;
  profile_start_time?: string | null;
  tag: IETagTemplateTag | null;
  to_entity: IEntityInfo | null;
}

export interface IETagTemplatesResponse {
  errorMessage: TErrorMessage;
  response: IETagTemplate[];
}

export interface IETagTemplateGroupsResponse {
  errorMessage: TErrorMessage;
  response: IETagTemplateGroup[];
}

export interface IETagTemplateNamesResponse {
  errorMessage: TErrorMessage;
  response: IETagTemplateName[];
}

export interface IETagTemplateResponse {
  errorMessage: TErrorMessage;
  response: IETagTemplate;
}

export interface IETagTemplateUpdate {
  template_id: TETagTemplateId;
}

export interface IETagTemplateGroup {
  group_name: string;
}

export interface IETagTemplateName {
  id: string;
}

export interface IETagTemplateUpdateResponse {
  errorMessage: TErrorMessage;
  response: IETagTemplateUpdate;
}

export interface IETagAuditStatuses {
  correction_id: string | null;
  created_by: string;
  created_by_sub: string;
  creation_time: string;
  message_type: string;
  request_id: string;
  security_key: string;
  success: boolean;
  tag_id: IETagTagId;
  to_entity_str: string;
}

export interface IETagAuditStatusesResponse {
  errorMessage: TErrorMessage;
  response: IETagAuditStatuses[];
}

export interface IETagRequestTagId extends IETagTagId {
  security_key: string;
  request_type: ERequestType;
}

export interface IETagTerminateTagRequest {
  tag_id: IETagRequestTagId;
  start: string;
  start_ramp_dur: number | null;
  contact_info: Omit<Partial<IContactInfo>, 'key'>;
  notes?: string;
}

export interface IETagWithdrawTagRequest {
  request_id: number;
  contact_info?: Omit<Partial<IContactInfo>, 'key'>;
}

export interface IETagCorrectionRequestTagId
  extends Exclude<IETagTagId, 'key' | 'tag_primary_key' | 'ui_tag_id'> {
  security_key: string;
}

export interface IETagTagCorrections {
  generation_physical_segment_correction?: IETagGenerationPhysicalSegment;
  load_physical_segment_correction?: IETagLoadPhysicalSegment;
  loss_accounting_corrections?: IETagLossAccounting[];
  market_segment_corrections?: IETagMarketSegment[];
  transmission_allocation_corrections?: IETagTransmissionAllocation[];
  transmission_physical_segment_corrections?: IETagTransmissionPhysicalSegment[];
}

export interface IETagCorrectionRequest {
  contact_info: Omit<Partial<IContactInfo>, 'key'>;
  notes?: string;
  tag_corrections?: IETagTagCorrections;
  tag_id: IETagCorrectionRequestTagId;
}

export interface IETagProfileChangeRequestTagId
  extends IETagCorrectionRequestTagId {
  request_type: ERequestType;
}

export interface IETagEnergyProfileChange {
  energy_profile_details: IETagEnergyProfileDetail[];
  profile_ref: number;
}

export interface IETagExceptionTransAllocProfileChange {
  changed_trans_alloc_profile_details: IETagTransmissionAllocationProfileDetail[];
  trans_alloc_id: number;
}

export interface IETagTagProfileChanges {
  base_trans_alloc_profile_changes?: IETagPathTransmissionAllocationProfile[];
  energy_profile_changes?: IETagEnergyProfileChange[];
  exception_trans_alloc_profile_changes?: IETagExceptionTransAllocProfileChange[];
  loss_accounting_changes?: IETagLossAccounting[];
}

export interface IETagProfileChangeRequestResponse {
  errorMessage: TErrorMessage;
  response: IETagProfileChangeRequest;
}

export interface IETagProfileChangeRequest {
  contact_info: Omit<Partial<IContactInfo>, 'key'>;
  misc_infos?: IMiscInfo[];
  notes?: string;
  tag_id: IETagProfileChangeRequestTagId;
  id?: string;
  tag_profile_changes?: IETagTagProfileChanges;
}

export interface IETagProfile {
  value: number;
  start_time: string;
  stop_time: string;
}

export interface IETagComputeProfileGenRequest {
  eastern_interconnect_gen: IETagReComputeProfileIntervals;
}

export interface IETagComputeProfileOverrideRequest {
  eastern_interconnect_override: IEtagProfileChangeEasternInterconnectOverride;
}

export interface IETagEnergyProfileOverride {
  profile_ref: number;
  profile: IETagProfile[];
}

export interface IETagEnergyTransAllocProfileOverride {
  aref: string;
  profile: IETagProfile[];
}

export interface IEtagProfileChangeEasternInterconnectOverride {
  existing_profile_change_id?: string;
  new_profile_change_key?: IEtagNewProfileChangeKey;
  overrides: IETagEnergyProfileOverride[];
}

export interface IEtagNewProfileChangeKey {
  tag_primary_key: string;
  request_type: string;
}

export interface IETagComputeProfileLoadRequest {
  eastern_interconnect_load: IETagReComputeProfileIntervals;
}

export interface IETagReComputeProfileIntervals {
  request_type: string;
  tag_primary_key: string;
  profile: IETagProfile[];
}

export interface IETagReComputeProfileRequest {
  compute_trans_allocs: {
    profile_change_id: string;
    overrides: IETagEnergyTransAllocProfileOverride[];
  };
}

export interface IETagValidationResult {
  is_valid: boolean;
  error_message: TErrorMessage;
  warning_message: TErrorMessage;
}

export interface IETagValidationResultList {
  valid: boolean;
  failure_list: IETagValidationResult[] | null;
  warning_list: IETagValidationResult[] | null;
}

export interface IETagDraftScheduleSubmission {
  draft_id: string;
  execution_time: string;
  scheduled_by: string;
}

export interface IETagValidationResponse {
  errorMessage: TErrorMessage;
  response: IETagValidationResultList;
}

export interface IETagScheduleDraftSubmissionResponse {
  errorMessage: TErrorMessage;
  warningMessage: TErrorMessage;
  response: IETagScheduleDraftSubmission;
}

export interface IETagScheduleDraftSubmission {
  draft_id: string;
  execution_id: string;
  execution_time: string;
  valid: boolean;
  failure_list: IETagValidationResult[] | null;
  warning_list: IETagValidationResult[] | null;
}

export interface IETagScheduledDraftResponse {
  errorMessage: TErrorMessage;
  response: IETagScheduledDraftSubmission[];
}

export interface IETagScheduledDraftSubmission {
  creation_time: string;
  draft_id: string;
  effective_current_time: string | null;
  execution_id: string;
  execution_time: string;
  scheduled_by: string;
  scheduled_by_sub: string;
  status: EDraftScheduleStatus;
  time_to_live: number;
  tries_count: string;
  tries_log: IETagScheduleSubmissionTryLog[];
  ui_tag_id: string;
}

export interface IETagScheduleSubmissionTryLog {
  message: string;
  status_code: string;
}

export interface IETagDeleteScheduledDraftSubmissionResponse {
  errorMessage: TErrorMessage;
  response: IETagDeleteSchduleDraftSubmission;
}

export interface IETagDeleteSchduleDraftSubmission {
  delete_count: number;
}

export interface IETagEditMode {
  allowATF?: boolean;
  mode: EEditMode;
}

export interface IETagEditModeResponse {
  errorMessage: TErrorMessage;
  response: IETagEditMode;
}

export interface IETagPendingRequest {
  approval_rights: boolean | null;
  key: string;
  last_action_taken: string | null;
  pci_suggested_action: EApprovalStatus | null;
  pci_suggested_note: string | null;
  request_act_on_by_time: string | null;
  request_id: number;
  request_note: string | null;
  request_start_time: string | null;
  request_stop_time: string | null;
  request_total_mwh: number | null;
  request_type: ERequestType | null;
  requestor: IEntityInfo | null;
  tag_primary_key: string;
  ui_tag_id: string;
}

export interface IETagPendingRequestsResponse {
  errorMessage: TErrorMessage;
  response: IETagPendingRequest[];
}

export interface IEtagTransmissionPriorityConfigurationResponse {
  errorMessage: TErrorMessage;
  response: IEtagTransmissionPriorityConfiguration[];
}

export interface IETagReasons {
  approve_reasons: string[];
  denied_reasons: string[];
  study_reasons: string[];
}

export interface IETagPutReasons {
  common_reasons_id: string;
}

export interface IETagReasonsResponse {
  errorMessage: TErrorMessage;
  response: IETagPutReasons | IETagReasons;
}

export interface IETagSetStateOnRequestResponse {
  errorMessage: TErrorMessage;
}

export interface IETagQueryResponse {
  errorMessage: TErrorMessage;
  response: IETagSummaryAttribute[] | null;
}

export interface IETagMarketInfoPrice {
  hour: string;
  mw: number | null;
  price: number | null;
}

export interface IETagMarketData {
  key: string;
}

export interface IETagMisoMarketData extends IETagMarketData {
  miso_create_fin_schedule: boolean | null;
  miso_market_date?: string | null;
  miso_market_type: EMarketInfoMarketType | null;
  miso_price_list: IETagMarketInfoPrice[];
  miso_transaction_type: EMarketInfoTransactionType | null;
}

export interface IETagSppMarketData extends IETagMarketData {
  spp_market_date?: string | null;
  spp_market_type: EMarketInfoMarketType | null;
  spp_price_list: IETagMarketInfoPrice[];
  spp_transaction_type: EMarketInfoTransactionType | null;
}

export interface IETagMarketInfo {
  market_info_market: EMarketInfoMarket;
  data: IETagMisoMarketData | IETagSppMarketData;
}

export interface IETagMarketInfoMiso extends IETagMarketInfo {
  market_info_market: EMarketInfoMarket.MISO;
  data: IETagMisoMarketData;
}

export interface IETagMarketInfoSpp extends IETagMarketInfo {
  market_info_market: EMarketInfoMarket.SPP;
  data: IETagSppMarketData;
}

export function isMiso(
  mktInfo: IETagMarketInfo | undefined,
): mktInfo is IETagMarketInfoMiso {
  return mktInfo?.market_info_market === EMarketInfoMarket.MISO;
}

export function isSpp(
  mktInfo: IETagMarketInfo | undefined,
): mktInfo is IETagMarketInfoSpp {
  return mktInfo?.market_info_market === EMarketInfoMarket.SPP;
}

export function findMiso(
  mktInfos: IETagMarketInfo[],
): IETagMarketInfoMiso | undefined {
  for (const mktInfo of mktInfos) {
    if (isMiso(mktInfo)) {
      return mktInfo;
    }
  }
  return undefined;
}

export function findSpp(
  mktInfos: IETagMarketInfo[],
): IETagMarketInfoSpp | undefined {
  for (const mktInfo of mktInfos) {
    if (isSpp(mktInfo)) {
      return mktInfo;
    }
  }
  return undefined;
}

export interface IETagMarketInfosResponse {
  errorMessage: TErrorMessage;
  response: IETagMarketInfo[];
}

export interface IETagLossPercentagesResponse {
  errorMessage: TErrorMessage;
  response: IETagDetailPhysicalSegmentLossPercentages;
}

export interface IETagMarketDetail {
  dateTimeFormat: TFormatTemplate;
  market: EMarketInfoMarket;
  timeZone: TTimeZone;
  timeZoneDisplayString: string;
}

export interface IETagConfig {
  market_info_markets?: EMarketInfoMarket[];
  losses_lite_enabled?: boolean;
  losses_v2_enabled?: boolean;
  hide_tenant_title?: boolean;
  combined_view?: boolean;
}

export interface IETagConfigResponse {
  errorMessage: TErrorMessage;
  response: IETagConfig;
}

export interface IETagCheckoutReportCreateResponse {
  errorMessage: TErrorMessage;
  response: IETagCheckoutReportCreate;
}

export interface IETagCheckoutReportCreate {
  messageId: string;
  requested: boolean;
}

export interface IETagCheckoutReport {
  attribute_names: string[];
  end: string;
  filter_ids: string;
  in_progress: boolean;
  requested_by: string;
  requested_by_sub: string;
  start: string;
  time_zone: TTimeZone;
  to_entity: TToEntityId;
  report_url: string | null;
  request_time: string;
}

export interface IETagCheckoutReportDataSet {
  attribute_names: string[];
  end: string;
  filter_ids: string;
  requested_by: string;
  requested_by_sub: string;
  file: {
    fileName: string | null;
    fileUrl: string | null;
    inProgress: boolean;
  };
  start: string;
  time_zone: TTimeZone;
  to_entity: TToEntityId;
  request_time: string;
}

export interface IETagCheckoutReportsReponse {
  errorMessage: TErrorMessage;
  response: IETagCheckoutReport[];
}

export interface IETagValidationReport {
  id?: string;
  periodicity: {
    day_of_month?: number;
    day_of_week?: string;
    hours_rate?: number;
    minutes_rate?: number;
    range_expression: string;
  };
  report_name: string;
  rules_list: string[];
  start_date: string;
  timezone: TTimeZone;
}

export interface IETagGetRulesResponse {
  errorMessage: TErrorMessage;
  response: IETagApprovalRule[];
}

export interface IETagScheduleReportResponse {
  errorMessage: TErrorMessage;
  response: { scheduled_failed_validations_report_id: string };
}

export interface IETagDeleteReportResponse {
  errorMessage: TErrorMessage;
  response: { delete_count: number };
}

export interface IETagGetScheduledFailedValidationsReportsResponse {
  errorMessage: TErrorMessage;
  response: IETagValidationReport[];
}

export interface IETagApprovalRule {
  id: string;
  name: string;
  // TODO: Add the rest of these properties
}

export interface IETagRuleDefinition {
  if_false: EApprovalStatus | null;
  if_true: EApprovalStatus | null;
  input: string | null;
  on_failure: null;
  operation_type: string;
  output: string;
  params: null;
}

export interface ITransmissionCapacityAvailabilityReportViewTags {
  ui_tag_id: string;
  tag_primary_key: string;
  transmission: number[];
}

export interface ITransmissionCapacityAvailabilityReportViewData {
  aref: string;
  transmission_provider: string | null;
  por: string | null;
  pod: string | null;
  path: string | null;
  request_type: string | null;
  offer_price: string | null;
  bid_price: string | null;
  ts_type: string | null;
  ts_class: string | null;
  service_increment: string | null;
  nerc_curtailment_priority: number | null;
  status: string | null;
  capacity_granted: number[] | null;
  available_capacity: number[] | null;
  custom_capacity_granted: number[] | null;
  tags: ITransmissionCapacityAvailabilityReportViewTags[] | null;
  start_date: string | null;
  end_date: string | null;
}

export interface ITransmissionAvailabilityReportView {
  start_date: string;
  granularity: string;
  data: ITransmissionCapacityAvailabilityReportViewData[];
}

export interface ITransmissionAvailabilityReportResponse {
  errorMessage: TErrorMessage;
  response: ITransmissionAvailabilityReportView;
}

export interface ITransmissionAvailabilityColumnDataAgGrid {
  aggFunc?: string | IAggFunc | null;
  field: string;
  expandTo?: IETagColumnDataAgGrid[];
  fixed?: EColumnPosition;
  headerClass?: string;
  headerName: string;
  isProfileData?: true;
  minWidth: number;
  maxWidth?: number;
  styleIndex?: string;
  type: EColumnType | undefined;
  rowGroup?: boolean;
  showRowGroup?: boolean;
  valueFormatter?: string | ValueFormatterFunc;
  hide?: boolean;
  sort?: 'asc' | 'desc' | null | undefined;
}

export interface ITransmissionAvailabilityDataSet {
  aref: string;
  transmission_provider: string | null;
  pod: string | null;
  por: string | null;
  request_type: string | null;
  offer_price: string | null;
  bid_price: string | null;
  ts_type: string | null;
  ts_class: string | null;
  service_increment: string | null;
  nerc_curtailment_priority: number | null;
  status: string | null;
  path: string | null;
  date: string | null;
  data_type: string | null;
  data_name: string | null;
  start_date: string | null;
  end_date: string | null;
  he_01?: number | null;
  he_02_extra?: number | null;
  he_02?: number | null;
  he_03?: number | null;
  he_04?: number | null;
  he_05?: number | null;
  he_06?: number | null;
  he_07?: number | null;
  he_08?: number | null;
  he_09?: number | null;
  he_10?: number | null;
  he_11?: number | null;
  he_12?: number | null;
  he_13?: number | null;
  he_14?: number | null;
  he_15?: number | null;
  he_16?: number | null;
  he_17?: number | null;
  he_18?: number | null;
  he_19?: number | null;
  he_20?: number | null;
  he_21?: number | null;
  he_22?: number | null;
  he_23?: number | null;
  he_24?: number | null;
  has_hour_data?: boolean;
  tag_primary_key?: string;
}

export interface ITransmissionServiceNetworkData {
  capacity_granted: number | null;
  start: string | null;
  stop: string | null;
}

export interface ITransmissionServiceNetwork {
  assignment_ref: string | null;
  data: ITransmissionServiceNetworkData[];
  transmission_provider?: string | null;
  por_name?: string | null;
  pod_name?: string | null;
  nerc_curtailment_priority?: number | null;
  source_name?: string | null;
  sink_name?: string | null;
}

export interface ITransmissionServiceNetworkResponse {
  errorMessage: TErrorMessage;
  response: ITransmissionServiceNetwork[];
}

export interface ITransmissionServiceCustomCapacity {
  assignment_ref: string | null;
  custom_capacity: number | null;
  start: string | null;
  stop: string | null;
}

export interface ITransmissionDetailPlusProfileId {
  trans_alloc_profile_detail: IETagTransmissionAllocationProfileDetail;
  trans_alloc_id: number;
}
