import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import DetailView from 'components/molecules/DetailView/DetailView';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import MarketInformation from 'components/organisms/MarketInformation/MarketInformation';
import {
  editMarketInfoToDetailState,
  getInitialMarketDate,
  getMarketDetailOptionsForEdit,
  getMarketDetailOptionsForReview,
  marketDetailToUid,
  marketInfosToDetailState,
} from 'components/organisms/MarketInformationView/helpers';
import useMarketInfos from 'components/organisms/MarketInformationView/useMarketInfos';
import { EDIT_MARKET_INFOS_LABEL } from 'constants/Detail';
import { MISO_MARKET_DETAIL, SPP_MARKET_DETAIL } from 'constants/ETag';
import { DATE_FORMAT } from 'constants/time';
import { EMarketInfoMarket } from 'enums/ETag';
import { ERetreiveState, ESeverity, EUpdateState } from 'enums/General';
import { EViewResize } from 'enums/View';
import { IOption, IValidationMessage } from 'interfaces/Component';
import {
  IEditMarketInfo,
  IEditMisoMarketData,
  IEditSppMarketData,
  IMarketInfos,
} from 'interfaces/Detail';
import {
  findMiso,
  findSpp,
  IETagMarketDetail,
  IETagMarketInfo,
  IETagMarketInfoMiso,
  IETagMarketInfoPrice,
  IETagMarketInfoSpp,
  IETagMisoMarketData,
  IETagSppMarketData,
  isMiso,
  isSpp,
} from 'interfaces/ETag';
import { IViewProps } from 'interfaces/View';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  detailEditETagDetail,
  detailSetValidations,
} from 'reduxes/Detail/actions';
import styled from 'styled-components';
import { TFormatTemplate, TTimeZone } from 'types/DateTime';
import { TDetailValidations } from 'types/Detail';
import { TRootState } from 'types/Redux';
import { getEditInfoKey, isMarketInfosEditable } from 'utils/detail';
import { isNonEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const MarketDatePicker = styled(DateTimePicker)`
  width: 100px;
`;

const MarketDate = styled.div`
  font-size: 12px;
`;

const MarketSelect = styled((props: ISelectProps<IETagMarketDetail>) =>
  Select<IETagMarketDetail>(props),
)`
  width: 67px;
`;

interface IMarketInformationViewProps extends IViewProps {}

const retrieveMarketInformationViewState = (state: TRootState) => {
  const {
    config,
    composite_state,
    marketInfos,
    pageMode,
    retrievingDetail,
    start_date,
    updatingDetail,
    viewMode,
  } = state.detail.present;
  const isDetailLoading: boolean =
    retrievingDetail !== ERetreiveState.NotRetrieving &&
    retrievingDetail !== ERetreiveState.RetrievingCompleted;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;

  return {
    config,
    composite_state,
    isDetailLoading,
    isDetailUpdating,
    marketInfos,
    pageMode,
    start_date,
    viewMode,
  };
};

const MarketInformationView = ({
  layoutGrid,
  resize,
  viewId,
}: IMarketInformationViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    isDetailLoading,
    isDetailUpdating,
    marketInfos,
    pageMode,
    start_date,
    viewMode,
  } = useSelector(retrieveMarketInformationViewState);
  const [selectedMarketDetail, setSelectedMarketDetail] = useState<
    IETagMarketDetail | undefined
  >(undefined);
  const [selectedMarketDate, setSelectedMarketDate] =
    useState<ZonedDateTime | null>(null);

  const isEditable: boolean = useMemo(
    (): boolean => isMarketInfosEditable(viewMode),
    [viewMode],
  );

  const marketInfoMarkets: EMarketInfoMarket[] = useMemo(
    () =>
      marketInfos.map(
        (marketInfo: IETagMarketInfo) => marketInfo.market_info_market,
      ),
    [marketInfos],
  );

  const { adjustedMarketInfos, initialMarketInfos }: IMarketInfos =
    useMarketInfos(
      marketInfoMarkets,
      marketInfos,
      isEditable,
      isDetailLoading,
      isDetailUpdating,
      pageMode,
      start_date,
    );

  useEffect(() => {
    if (!isDetailLoading && adjustedMarketInfos !== marketInfos) {
      dispatch(
        detailEditETagDetail({
          // We must include isDetailEdited: true in order to ensure that
          // things like save, undo, redo etc. recognises a change in the detail
          // state.
          isDetailEdited: true,
          isMarketInfosEdited: true,
          stateTransform: marketInfosToDetailState(adjustedMarketInfos),
        }),
      );
    }
  }, [adjustedMarketInfos, dispatch, isDetailLoading, marketInfos]);

  const marketDetailOptions: IOption<IETagMarketDetail>[] = useMemo(
    () =>
      isEditable
        ? getMarketDetailOptionsForEdit(marketInfoMarkets)
        : getMarketDetailOptionsForReview(adjustedMarketInfos),
    [adjustedMarketInfos, isEditable, marketInfoMarkets],
  );

  useEffect(() => {
    if (selectedMarketDetail === undefined && marketDetailOptions.length > 0) {
      const marketDetailOptionIndex: number =
        adjustedMarketInfos.length > 0
          ? marketDetailOptions.findIndex(
              (option: IOption<IETagMarketDetail>): boolean => {
                const enabledMarketInfo = adjustedMarketInfos[0];
                return (
                  option.value.market === enabledMarketInfo.market_info_market
                );
              },
            )
          : -1;
      if (marketDetailOptionIndex !== -1) {
        setSelectedMarketDetail(
          marketDetailOptions[marketDetailOptionIndex].value,
        );
      }
    }
  }, [adjustedMarketInfos, marketDetailOptions, selectedMarketDetail]);

  useEffect(() => {
    if (adjustedMarketInfos.length > 0) {
      if (!isDetailLoading && selectedMarketDetail !== undefined) {
        const { market, timeZone } = selectedMarketDetail;
        const marketInfo: IETagMarketInfo | undefined =
          adjustedMarketInfos.find(
            (marketInfo: IETagMarketInfo): boolean =>
              marketInfo.market_info_market === market,
          );

        if (marketInfo === undefined) {
          throw new Error(`Invalid market: ${market}`);
        } else {
          let dateTimeFormat: TFormatTemplate | undefined = undefined;
          let marketDateString: string | null | undefined = undefined;
          let marketDate: ZonedDateTime | undefined = undefined;

          if (market === EMarketInfoMarket.MISO) {
            marketDateString = (marketInfo.data as IETagMisoMarketData)
              .miso_market_date;

            dateTimeFormat = MISO_MARKET_DETAIL.dateTimeFormat;
          } else if (market === EMarketInfoMarket.SPP) {
            marketDateString = (marketInfo.data as IETagSppMarketData)
              .spp_market_date;

            dateTimeFormat = SPP_MARKET_DETAIL.dateTimeFormat;
          }

          if (isNonEmptyValue(marketDateString)) {
            if (dateTimeFormat === undefined) {
              marketDate = ZonedDateTime.parseIso(marketDateString, timeZone);
            } else {
              marketDate = ZonedDateTime.parse(
                marketDateString,
                timeZone,
                dateTimeFormat,
              );
            }
          }

          if (marketDate === undefined && start_date !== null) {
            marketDate = ZonedDateTime.parseIso(start_date, timeZone);
          }

          if (marketDate !== undefined) {
            setSelectedMarketDate(marketDate);
          }
        }
      }
    } else {
      setSelectedMarketDate(null);
    }
  }, [
    adjustedMarketInfos,
    initialMarketInfos,
    isDetailLoading,
    selectedMarketDetail,
    start_date,
  ]);

  const handleMarketInformationChange = useCallback(
    (editMarketInfo: IEditMarketInfo) => {
      dispatch(
        detailEditETagDetail({
          // We must include isDetailEdited: true in order to ensure that
          // things like save, undo, redo etc. recognises a change in the detail
          // state.
          isDetailEdited: true,
          isMarketInfosEdited: true,
          stateTransform: editMarketInfoToDetailState(editMarketInfo),
        }),
      );
    },
    [dispatch],
  );

  const handleMarketDetailChange = useCallback(
    (eTagMarketDetail: IETagMarketDetail | undefined) => {
      const marketInfo: IETagMarketInfo | undefined = adjustedMarketInfos.find(
        (marketInfo: IETagMarketInfo): boolean =>
          marketInfo.market_info_market === eTagMarketDetail?.market,
      );

      let marketDateTimeString: string | null | undefined = undefined;
      let timeZone: TTimeZone | undefined = undefined;
      let dateTimeFormat: TFormatTemplate | undefined = undefined;

      if (isMiso(marketInfo)) {
        marketDateTimeString = marketInfo.data.miso_market_date;
        timeZone = MISO_MARKET_DETAIL.timeZone;
        dateTimeFormat = MISO_MARKET_DETAIL.dateTimeFormat;
      } else if (isSpp(marketInfo)) {
        marketDateTimeString = marketInfo.data.spp_market_date;
        timeZone = SPP_MARKET_DETAIL.timeZone;
        dateTimeFormat = SPP_MARKET_DETAIL.dateTimeFormat;
      }

      if (
        isNonEmptyValue(marketDateTimeString) &&
        timeZone !== undefined &&
        dateTimeFormat !== undefined
      ) {
        setSelectedMarketDate(
          ZonedDateTime.parse(marketDateTimeString, timeZone, dateTimeFormat),
        );
      }

      setSelectedMarketDetail(eTagMarketDetail);
    },
    [adjustedMarketInfos],
  );

  const handleMarketDateChange = useCallback(
    (zonedDateTime: ZonedDateTime | null) => {
      if (selectedMarketDetail !== undefined && zonedDateTime !== null) {
        const marketInfo: IETagMarketInfo | undefined =
          adjustedMarketInfos.find(
            (marketInfo: IETagMarketInfo): boolean =>
              marketInfo.market_info_market === selectedMarketDetail.market,
          );

        const editMarketInfo: IEditMarketInfo = {
          data: {},
          market: selectedMarketDetail.market,
        };
        let hasMarketDataDateChanged: boolean = false;

        if (isMiso(marketInfo)) {
          const { miso_market_date } = marketInfo.data;
          const marketDate: ZonedDateTime = zonedDateTime.withTimeZone(
            'UTC',
            false,
          );
          const updatedMarketDate: string = marketDate.format(
            MISO_MARKET_DETAIL.dateTimeFormat,
          );

          if (miso_market_date !== updatedMarketDate) {
            (editMarketInfo.data as IEditMisoMarketData).miso_market_date =
              updatedMarketDate;

            hasMarketDataDateChanged = true;
          }
        } else if (isSpp(marketInfo)) {
          const { spp_market_date } = marketInfo.data;
          const marketDate: ZonedDateTime = zonedDateTime.withTimeZone(
            'UTC',
            false,
          );
          const updatedMarketDate: string = marketDate.format(
            SPP_MARKET_DETAIL.dateTimeFormat,
          );

          if (spp_market_date !== updatedMarketDate) {
            (editMarketInfo.data as IEditSppMarketData).spp_market_date =
              updatedMarketDate;

            hasMarketDataDateChanged = true;
          }
        }

        setSelectedMarketDate(zonedDateTime);

        if (hasMarketDataDateChanged) {
          handleMarketInformationChange(editMarketInfo);
        }
      }
    },
    [adjustedMarketInfos, handleMarketInformationChange, selectedMarketDetail],
  );

  const isValidSppMarketInfo = (sppData: IETagSppMarketData): boolean =>
    !!sppData.spp_market_date &&
    !!sppData.spp_market_type &&
    !!sppData.spp_transaction_type;

  const isValidMisoMarketInfo = (misoData: IETagMisoMarketData): boolean =>
    misoData.miso_create_fin_schedule !== undefined &&
    misoData.miso_create_fin_schedule !== null &&
    !!misoData.miso_market_type &&
    !!misoData.miso_transaction_type &&
    !!misoData.miso_market_date;

  const isValidPriceInfo = (priceInfo: IETagMarketInfoPrice): boolean => {
    if (
      (!priceInfo.price && !priceInfo.mw) ||
      (priceInfo.price && priceInfo.mw)
    ) {
      return true;
    } else if (
      (priceInfo.price && !priceInfo.mw) ||
      (!priceInfo.price && priceInfo.mw)
    ) {
      return false;
    }
    return false;
  };

  const validateMarketInfoData = useCallback(() => {
    const detailValidations: TDetailValidations = {};
    const validationMessages: IValidationMessage[] = [];
    if (selectedMarketDetail !== undefined) {
      const sppMarketInfo: IETagMarketInfoSpp | undefined =
        findSpp(adjustedMarketInfos);

      const misoMarketInfo: IETagMarketInfoMiso | undefined =
        findMiso(adjustedMarketInfos);
      const invalidMarketInfoMsgs: string[] = [];
      if (
        misoMarketInfo !== undefined &&
        !isValidMisoMarketInfo(misoMarketInfo.data)
      ) {
        invalidMarketInfoMsgs.push('MISO');
      }
      if (
        sppMarketInfo !== undefined &&
        !isValidSppMarketInfo(sppMarketInfo.data)
      ) {
        invalidMarketInfoMsgs.push('SPP');
      }

      if (invalidMarketInfoMsgs.length > 0) {
        validationMessages.push({
          message: `${invalidMarketInfoMsgs.join(
            ' and ',
          )} market info is incomplete`,
          severity: ESeverity.Warning,
        });
      } else {
        const invalidMarketInfoPriceMsgs: string[] = [];
        for (const misoPrice of misoMarketInfo?.data?.miso_price_list ?? []) {
          if (misoPrice !== undefined && !isValidPriceInfo(misoPrice)) {
            invalidMarketInfoPriceMsgs.push('MISO');
            break;
          }
        }
        for (const sppPrice of sppMarketInfo?.data?.spp_price_list ?? []) {
          if (sppPrice !== undefined && !isValidPriceInfo(sppPrice)) {
            invalidMarketInfoPriceMsgs.push('SPP');
            break;
          }
        }

        if (invalidMarketInfoPriceMsgs.length > 0) {
          validationMessages.push({
            message: `${invalidMarketInfoPriceMsgs.join(
              ' and ',
            )} market info prices are incomplete`,
            severity: ESeverity.Error,
          });
        }
      }
    }
    detailValidations[getEditInfoKey(EDIT_MARKET_INFOS_LABEL, 0, 0)] = true;
    return {
      detailValidations: detailValidations,
      validationMessages: validationMessages,
    };
  }, [adjustedMarketInfos, selectedMarketDetail]);

  const initialMarketDate: ZonedDateTime | null | undefined = useMemo(
    (): ZonedDateTime | null | undefined =>
      isEditable
        ? getInitialMarketDate(selectedMarketDetail?.market, initialMarketInfos)
        : undefined,
    [initialMarketInfos, isEditable, selectedMarketDetail],
  );

  const actions = useMemo(() => {
    const isDisabled: boolean = isDetailLoading || isDetailUpdating;
    return (
      <SeparatedRowLayout>
        <MarketSelect
          isDisabled={isDisabled}
          onChange={handleMarketDetailChange}
          options={marketDetailOptions}
          placeholder='Select Market'
          value={selectedMarketDetail}
          valueToUid={marketDetailToUid}
        />
        {selectedMarketDetail === undefined ? null : isEditable ? (
          <MarketDatePicker
            format={DATE_FORMAT}
            initialValue={isDisabled ? undefined : initialMarketDate}
            isDisabled={isDisabled}
            onChange={handleMarketDateChange}
            placeholder=''
            timeZone={selectedMarketDetail.timeZone}
            value={selectedMarketDate}
          />
        ) : (
          <MarketDate>{selectedMarketDate?.format(DATE_FORMAT)}</MarketDate>
        )}
      </SeparatedRowLayout>
    );
  }, [
    handleMarketDateChange,
    handleMarketDetailChange,
    initialMarketDate,
    isDetailLoading,
    isDetailUpdating,
    isEditable,
    marketDetailOptions,
    selectedMarketDate,
    selectedMarketDetail,
  ]);

  const { detailValidations, validationMessages } = useMemo(() => {
    if (isDetailLoading) {
      return {
        detailValidations: undefined,
        validationMessages: undefined,
      };
    }
    return validateMarketInfoData();
  }, [isDetailLoading, validateMarketInfoData]);

  useEffect(() => {
    if (detailValidations !== undefined) {
      dispatch(detailSetValidations({ detailValidations }));
    }
  }, [detailValidations, dispatch]);

  return (
    <>
      {marketInfoMarkets.length > 0 && (
        <DetailView
          className={'market-information-view'}
          isLoading={isDetailLoading}
          layoutGrid={layoutGrid}
          leftActions={actions}
          resize={resize}
          title='Market Information'
          validationMessages={validationMessages}
          viewId={viewId}
          viewResizeSetting={EViewResize.Initial}
        >
          {selectedMarketDate !== null &&
            selectedMarketDetail !== undefined && (
              <MarketInformation
                initialMarketInfos={initialMarketInfos}
                isDisabled={isDetailUpdating || isDetailUpdating}
                isEditable={isEditable}
                isUnconstrained={false}
                marketDate={selectedMarketDate}
                marketDetail={selectedMarketDetail}
                marketInfos={adjustedMarketInfos}
                onChange={handleMarketInformationChange}
              />
            )}
        </DetailView>
      )}
    </>
  );
};

export default MarketInformationView;
