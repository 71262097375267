import { INITIAL_RECORD_ID } from 'constants/Detail';
import { MISO_MARKET_DETAIL, SPP_MARKET_DETAIL } from 'constants/ETag';
import { EMarketInfoMarket } from 'enums/ETag';
import { IOption } from 'interfaces/Component';
import { IEditMarketInfo } from 'interfaces/Detail';
import {
  IETagMarketDetail,
  IETagMarketInfo,
  IETagMisoMarketData,
  IETagSppMarketData,
} from 'interfaces/ETag';
import { IDetailState } from 'reduxes/Detail/types';
import { TStateTransform } from 'types/General';
import { getEditInfoKey, getSplitEditInfoKey } from 'utils/detail';
import { getMarketDetailOptionForMarket } from 'utils/eTag';
import { isNonEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const getMarketDetailOptionsForEdit = (
  markets: EMarketInfoMarket[],
): IOption<IETagMarketDetail>[] => markets.map(getMarketDetailOptionForMarket);

export const getMarketDetailOptionsForReview = (
  marketInfos: IETagMarketInfo[],
): IOption<IETagMarketDetail>[] =>
  marketInfos.map(
    (marketInfo: IETagMarketInfo): IOption<IETagMarketDetail> =>
      getMarketDetailOptionForMarket(marketInfo.market_info_market),
  );

export const marketDetailToUid = (marketDetail: IETagMarketDetail): string =>
  marketDetail.market as string;

export const marketInfosToDetailState = (
  marketInfos: IETagMarketInfo[],
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => ({
    ...detailState,
    marketInfos,
  });
};

export const editMarketInfoToDetailState = (
  editMarketInfo: IEditMarketInfo,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const updatedDetailState: IDetailState = { ...detailState };

    updatedDetailState.marketInfos = updatedDetailState.marketInfos.map(
      (marketInfo: IETagMarketInfo): IETagMarketInfo => {
        const { data, market_info_market } = marketInfo;

        if (market_info_market === editMarketInfo.market) {
          const { key } = data;
          const { editIndex, label, primaryId } = getSplitEditInfoKey(key);

          return {
            data: {
              ...data,
              ...editMarketInfo.data,
              key:
                primaryId === INITIAL_RECORD_ID
                  ? getEditInfoKey(label, 1, editIndex)
                  : key,
            },
            market_info_market,
          };
        }

        return marketInfo;
      },
    );

    return updatedDetailState;
  };
};

export const getInitialMarketDate = (
  market?: EMarketInfoMarket,
  initialMarketInfos?: IETagMarketInfo[],
): ZonedDateTime | null | undefined => {
  if (market === undefined) {
    return undefined;
  }

  const initialMarketInfo: IETagMarketInfo | undefined =
    initialMarketInfos?.find(
      (marketInfo: IETagMarketInfo): boolean =>
        marketInfo.market_info_market === market,
    );

  if (initialMarketInfo === undefined) {
    return null;
  }

  if (market === EMarketInfoMarket.MISO) {
    const marketDate: string | null | undefined = (
      initialMarketInfo.data as IETagMisoMarketData
    ).miso_market_date;

    return isNonEmptyValue(marketDate)
      ? ZonedDateTime.parseIso(marketDate, MISO_MARKET_DETAIL.timeZone)
      : null;
  } else if (market === EMarketInfoMarket.SPP) {
    const marketDate: string | null | undefined = (
      initialMarketInfo.data as IETagSppMarketData
    ).spp_market_date;

    return isNonEmptyValue(marketDate)
      ? ZonedDateTime.parseIso(marketDate, SPP_MARKET_DETAIL.timeZone)
      : null;
  } else {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`Invalid market: ${market}`);
  }
};
